import { AcarsFormData } from '../pages/rtow/acarsl1-1';
import axios from "axios";
import webConfig from '../config';
const { SERVER_BASE_URL, WHITE_LIST_API } = webConfig;

export const postAcarsl2DataByAxios = async (
  params: AcarsFormData
): Promise<any> => {
  console.log(params);

  const baseUrl = `${SERVER_BASE_URL}/acarsl2`;
  const queryParams = params && new URLSearchParams(Object.entries(params));
  const url = baseUrl + `?${queryParams}`;
  const headers = {
    'Cache-Control': 'no-store, max-age=0',
    Accept: '*/*',
    "Content-Type": "application/json",
  };
  const response = await axios.post(url, undefined, { headers });
  return response?.data;
};
