import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Show777, ShowAirbus, ShowCommon, Show400 } from './acars1-1';
import { postAcars1DataByAxios, RequestParams } from '../../services/acars1';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Header } from 'antd/es/layout/layout';
interface AcarsData {
  actype: string;
  icao: string;
  iata: string;
  acreg: string;
  fltnum: string;
  mtow: number;
  acpacks: string;
  rwylist: [string, string][];
  ai_options: string[];
  flap_options: string[];
  rating_options: string[];
  mel_options: string[];
  surface_options: string[];
  eng_options: string[];
  wng_options: string[];
  is_rwy_ready: boolean;
  nextHeader: string;
  timenow: string;
  timestamp: string;
  header: string;
  next_header: string;
}
export interface FormFields {
  actype: string;
  apcode: string;
  icao: string;
  acreg: string;
  fltnum: string;
  rwy: string;
  agtow: string;
  oat: string;
  surface: string;
  qnh: string;
  flap: string;
  wind: string;
  winddir: string;
  windmag: string;
  rating: string;
  packs: string;
  mel1: string;
  mel2: string;
  cdl: string;
  adhoc: string;
  acg: string;
  depth: string;
  antiice: string;
  wantiice: string;
}
const Acars1: React.FC = () => {
  const [formData, setFormData] = useState<FormFields>();
  const [acarsData, setAcarsData] = useState<AcarsData>();
  const [pageError, setPageError] = useState<string>('');
  const list777 = ['B772', 'B784', '73ER'];
  const listAirbus = [
    'A320',
    'A321',
    'A32I',
    'A32N',
    'A330',
    'A333',
    'A33B',
    'K330',
    'K33B',
    'K333',
    'A33E',
    'K33E',
    'L332',
    'L33Z',
    'L33B',
    'L33E',
    'A340',
    'A306',
    'A300',
  ];
  const list400 = ['400G', '40FG', '40PW', '4ERF', '7478'];
  const location = useLocation();
  if (!location.state) {
    return <Navigate to={`${(window as any)._navbase}/acars0`} replace/>;
  }
  const {state,p1State} = location.state ;
  useEffect(() => {
    console.log("LOG - [page 1] state:",state) 
    console.log("LOG - [page 1] p1State:",p1State) 
    const fetchData = async () => {
      try {
        const response = await postAcars1DataByAxios(state).catch((error) => {
          console.error('Error fetching data:', error);
          setPageError(error.message);
        });
        console.log(response);
        setAcarsData(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  const navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent) => {
    const form = event.currentTarget as HTMLFormElement;
    const formValues = new FormData(form);
    const data = Object.fromEntries(formValues.entries());
  
    const params = data as unknown as FormFields;
    event.preventDefault();
    setFormData(params);
    console.log("LOG - Handle Submit");
    console.log("LOG - formValues:",formValues);
    console.log("LOG - data:",data);
    console.log("LOG - params:",params);
    navigate(`${(window as any)._navbase}/acars2`, {
      state: { ...params, "header": acarsData?.next_header,"state":state,"p1State":params },
    });
  };
  const handleGoBack = (e: React.FormEvent) => {
    e.preventDefault();
    navigate(`${(window as any)._navbase}/acars0`, { state: state });
  };
  return (
    <>
      <Helmet>
        <title>{state ? `RTOW Page 1` : 'Loading...'}</title>
        <meta name='description' content='RTOW Application Page' />
      </Helmet>
      <button onClick={handleGoBack} style={{borderRadius:"3px",borderWidth:"0.5px", fontSize:"12pt"}}>Back</button>
      <h3 id='header'>{acarsData?.header}</h3>
      <div id='process' className='content'></div>
      {acarsData ? (
        <div id='content'>
          {acarsData?.actype && !list777.includes(acarsData.actype) && (
            <p>
              Departure port : {acarsData.icao} ({acarsData.iata})
            </p>
          )}
          {acarsData?.is_rwy_ready ? (
            <>
              <input type='hidden' id='next_header' value={acarsData?.nextHeader} />
              <form name='acinfo' action='/acars2' method='post' onSubmit={handleSubmit}>
                <input type='hidden' name='actype' value={acarsData?.actype} />
                <input type='hidden' name='apcode' value={acarsData?.iata} />
                <input type='hidden' name='icao' value={acarsData?.icao} />
                <input type='hidden' name='acreg' value={acarsData?.acreg} />
                <input type='hidden' name='fltnum' value={acarsData?.fltnum} />
                {list777.includes(acarsData?.actype) ? (
                  <Show777 {...acarsData} formData={formData} setFormData={setFormData} p1State={p1State} />
                ) : (
                  <>
                    <table>
                      <ShowCommon {...acarsData} formData={formData} setFormData={setFormData} p1State={p1State}/>
                      {listAirbus.includes(acarsData?.actype || '') && (
                        <ShowAirbus {...acarsData} formData={formData} setFormData={setFormData} p1State={p1State}/>
                      )}
                      {list400.includes(acarsData?.actype || '') && (
                        <Show400 {...acarsData} formData={formData} setFormData={setFormData} p1State={p1State}/>
                      )}
                    </table>
                  </>
                )}
              </form>
            </>
          ) : (
            <p>No valid runway found!</p>
          )}
          <br />
          <p className='content'>
            <i>
              Generated on {acarsData?.timenow} (HKG LT)
              <br />
              by Ops Eng, FOP, Cathay Pacific Airways
            </i>
          </p>
        </div>
      ) : pageError ? (
        pageError
      ) : (
        'Loading...'
      )}
    </>
  );
};
export default Acars1;