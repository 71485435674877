import APMPref from '../../types/apm/APMPref';
import { truncate } from '../../utils/MathUtils';

type ColumnConfig = {
  label: string;
  key?: keyof APMPref;
  display?: (pref: APMPref) => any;
  className?: string;
};

const REG: ColumnConfig = { label: 'Registration', key: 'Reg' };
const MTOW: ColumnConfig = {
  label: 'MTOW (Tonne)',
  display: (pref) => {
    if (['HOV', 'HUJ', 'HOT', 'HOY', 'HOP', 'HOS', 'HOW', 'HOX'].includes(pref.Reg)) {
      return 394.6;
    }
    return 394.8;
  },
};
const BW: ColumnConfig = { label: 'Basic Weight(kg)', key: 'BW' };
const PERF: ColumnConfig = { label: 'Perf', display: (pref) => truncate(pref.Perf, 1) };
const DPL: ColumnConfig = {
  label: 'Payload Capability <br/>Compared to the best<br/> aircraft',
  display: (pref) => pref.DPL.toFixed(0),
  className: 'apm-table-pref-dpl',
};
const FDPL: ColumnConfig = {
  label: 'Fuel Tank Limited case: Payload<br/> Capability Compared <br/>to the Best aircraft',
  display: (pref) => pref.FDPL.toFixed(0),
};

const tableConfig: ColumnConfig[] = [REG, BW, PERF, DPL];

const tableConfigB747: ColumnConfig[] = [REG, MTOW, BW, PERF, DPL];

const tableConfigExclude: ColumnConfig[] = [REG, BW, PERF, DPL, FDPL];

function getPrefType(acType: string) {
  if (acType.startsWith('A350-900B')) {
    return 'A359';
  } else if (acType.startsWith('A350-900P')) {
    return '359P';
  } else if (acType.startsWith('A350-1000')) {
    return 'A351';
  }
  return acType.substring(0, 4);
}

function getTitleType(acType: string) {
  if (acType.startsWith('B747')) {
    return acType.substring(0, 8);
  }
  return acType.substring(0, 10);
}

function getTableConfig(prefType: string) {
  if (prefType === 'B747') {
    return tableConfigB747;
  } else if (!['B777', 'A359', 'A351', '359P'].includes(prefType)) {
    return tableConfigExclude;
  }
  return tableConfig;
}

export type { ColumnConfig };
export { getTableConfig, getPrefType, getTitleType };
