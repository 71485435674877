import APMPref from '../../types/apm/APMPref';
import { getPrefType, getTableConfig, getTitleType } from './apmPrefTableConfigs';

const ApmPrefTable = ({ apmPrefs, acType }: { apmPrefs: APMPref[]; acType: string }) => {
  const prefType = getPrefType(acType);
  const titleType = getTitleType(acType);
  const tableConfig = getTableConfig(prefType);
  return (
    <>
      <h4>{`${titleType} Preference List (The best aircraft at top of table)`}</h4>
      <table className='apm-table apm-table-pref'>
        <thead>
          <tr>
            {tableConfig.map((config, i) => (
              <th key={`pref_${i}`} dangerouslySetInnerHTML={{ __html: config.label }}></th>
            ))}
          </tr>
        </thead>
        <tbody>
          {apmPrefs.map((pref, i) => (
            <tr key={`pref_${i}`}>
              {tableConfig.map((config) => (
                <td key={config.label} className={config.className}>
                  {config.display ? config.display(pref) : pref[config.key!]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ApmPrefTable;
