import { ReactNode } from 'react';

type LoadingProps = {
  complete: boolean;
  error: string;
  loadingMessage?: string;
  children: ReactNode;
};

const defaultLoadingMessage = 'Loading...';

const Loading = (props: LoadingProps) => {
  if (props.error) {
    return <>{props.error}</>;
  }
  if (props.complete) {
    return <>{props.children}</>;
  }
  return <>{props.loadingMessage || defaultLoadingMessage}</>;
};

export default Loading;
