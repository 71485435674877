import React, { useEffect, useState } from 'react';
import { FormFields } from './acars1';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { postAcars2DataByAxios } from '../../services/acars2';
import { Helmet } from 'react-helmet';
import Paragraph from 'antd/es/typography/Paragraph';
interface Props {
  result: string[];
  timestamp: string;
}
const Acarsl2: React.FC = () => {
  const location = useLocation();
  if (!location.state) {
    return <Navigate to={`${(window as any)._navbase}/acars0`} replace/>;
  }
  const state = { ...location.state, header: undefined } ;
  const [pageError, setPageError] = useState<string>('');
  const [props, setProps] = React.useState<Props>();
  useEffect(() => {
    console.log("LOG - [P2] State",state.state)
    const fetchLandingData = async () => {
      try {
        const response = await postAcars2DataByAxios({
          ...state,
          packs: state.packs || '',
          adhoc: state.adhoc || '',
          acg: state.acg || '',
          depth: state.depth || '',
          antiice: state.antiice || '',
          wantiice: state.wantiice || '',
        }).catch((error) => {
          console.error('Error fetching data:', error);
          setPageError(error.message);
        });
        setProps(response);
      } catch (error) {
        console.error('Error fetching landing data:', error);
      }
    };
    state && fetchLandingData();
  }, []);
  const navigate = useNavigate()
  const handleGoBack = (e: React.FormEvent) => {
    e.preventDefault();
    navigate(`${(window as any)._navbase}/acars1`,{state:{"state":state.state,"p1State":state.p1State}});
  };
  return (
    <>
      <Helmet>
        <title>RTOW Page 2</title>
        <meta name='description' content='RTOW Application Page' />
      </Helmet>
      <button onClick={handleGoBack} style={{borderRadius:"3px",borderWidth:"0.5px", fontSize:"12pt"}}>Back</button>
      <h3 id='header'>{location.state?.header}</h3>
      <div>
        {props ? (
          <div>
            <Paragraph style={{ whiteSpace: 'pre', fontFamily: 'monospace' }}>
              {props?.result}
            </Paragraph>
          </div>
        ) : pageError ? (
          pageError
        ) : (
          'Processing...'
        )}
      </div>
    </>
  );
};
export default Acarsl2;