import { ApmReferenceHook } from '../../hooks/apm/apm';
import { APMReferenceInfo } from '../../types/apm/APMReference';
import { getTableConfig } from './apmReferenceTableConfigs';

const ApmReferenceEdit = ({
  acType,
  apmReferenceInfo,
  apmReferenceHook,
}: {
  acType: string;
  apmReferenceInfo: APMReferenceInfo;
  apmReferenceHook: ApmReferenceHook;
}) => {
  const {
    addReference,
    copyReference,
    deleteReference,
    updateReferenceValue,
    updateDescription,
    updateSuffixMap,
  } = apmReferenceHook;
  const { references, description, suffixMap } = apmReferenceInfo;
  const tableConfig = getTableConfig(acType);
  return (
    <>
      <textarea
        name='description'
        value={description}
        onChange={(e) => updateDescription(e.target.value)}
        style={{
          width: '50%',
        }}
      />

      <table className='apm-table'>
        <thead>
          <tr>
            {tableConfig.map((config, i) => (
              <th key={`reference_${i}`}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${config.label}`,
                  }}
                ></span>
                <input
                  style={{ width: '35px', textAlign: 'center', marginLeft: '5px' }}
                  type='text'
                  name={config.key}
                  value={suffixMap[config.key] || ''}
                  onChange={(e) => updateSuffixMap(config.key, e.target.value)}
                />
              </th>
            ))}

            <th>
              <button
                style={{
                  borderRadius: '5px',
                  color: 'green',
                }}
                onClick={(_) => addReference()}
              >
                +
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {references.map((reference, i) => {
            return (
              <tr key={`reference_${i}`}>
                {tableConfig.map((config) => (
                  <td key={config.label} className={config.className}>
                    <input
                      style={{ width: '100%', textAlign: 'center' }}
                      type='text'
                      name={config.key}
                      value={reference[config.key] || ''}
                      onChange={(e) => updateReferenceValue(i, config.key, e.target.value)}
                    />
                  </td>
                ))}

                <td>
                  <button
                    style={{
                      borderRadius: '5px',
                      color: 'red',
                    }}
                    onClick={(_) => deleteReference(i)}
                  >
                    -
                  </button>
                  <button
                    style={{
                      borderRadius: '5px',
                    }}
                    onClick={(_) => copyReference(i)}
                  >
                    Copy
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default ApmReferenceEdit;
