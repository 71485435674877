import React, { useState } from 'react';
import { getApmDataByAxios, getApmDetailByAxios, postApmDetailByAxios } from '../../services/apm';
import APM from '../../types/apm/APM';
import APMFilter from '../../types/apm/APMFilter';
import { APMReference, APMReferenceInfo } from '../../types/apm/APMReference';
import APMPref from '../../types/apm/APMPref';

function useApm(pageErrorState: [string, React.Dispatch<React.SetStateAction<string>>]) {
  const setPageError = pageErrorState[1];
  const [apms, setApms] = useState<APM[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const [complete, setComplete] = useState<boolean>(false);

  const fetchApmData = async () => {
    setComplete(false);
    const data = await getApmDataByAxios().catch((err) =>
      setPageError(`Error fetching data: ${err}`)
    );

    if (!data) return;

    setApms(data.apm_data);
    setTypes(data.types);
    setComplete(true);
  };

  function filterApm(apmFilter: APMFilter) {
    const referencesRegs = filterReferenceRegs(apmFilter.apmReferences || []);

    return apms.filter((apm) => {
      let match = true;
      match = match && (apmFilter.type === '' || apm.Type === apmFilter.type);
      match = match && (!referencesRegs.length || !referencesRegs.includes(apm.Reg));
      return match;
    });
  }

  return { apms, types, fetchApmData, complete, filterApm };
}

function useApmDetail(pageErrorState: [string, React.Dispatch<React.SetStateAction<string>>]) {
  const setPageError = pageErrorState[1];
  const [apmReferenceInfo, setApmReferenceInfo] = useState<APMReferenceInfo | undefined>(undefined);
  const [apmPrefs, setApmPrefs] = useState<APMPref[]>([]);
  const [complete, setComplete] = useState<boolean>(false);

  const fetchApmDetailData = async (acType: string) => {
    setComplete(false);
    if (!acType) {
      setApmReferenceInfo(undefined);
      setApmPrefs([]);
      setComplete(true);
      return;
    }

    const data = await getApmDetailByAxios({ actype: acType }).catch((err) => {
      setPageError(`Error fetching data: ${err}`);
    });

    if (!data) return;

    setApmReferenceInfo(data.reference);

    if (
      acType.startsWith('A320-232') ||
      acType.startsWith('A340-313') ||
      acType.startsWith('A350-900B') ||
      acType.startsWith('A350-900P') ||
      acType.startsWith('A350-1000') ||
      acType.startsWith('B747-400RR') ||
      acType.startsWith('B747-400PW') ||
      acType.startsWith('B777-300ER')
    ) {
      setApmPrefs(data.prefs);
    } else {
      setApmPrefs([]);
    }

    setComplete(true);
  };

  return { apmReferenceInfo, apmPrefs, fetchApmDetailData, complete };
}

function filterReferenceRegs(apmReference: APMReference[]) {
  return apmReference.map((reference) => {
    // reference Reg e.g. 'HSJ^'
    return /[A-Z]{3}/.exec(reference.Reg)?.[0] || '';
  });
}

const emptyReference: APMReference = {
  Type: '',
  Reg: '',
  FF: '',
  Drag: '',
  Climb: '',
  Perf: '',
  Idle: '',
  IssueDate: '',
  EngChgDate: '',
};
const defaultReference: APMReferenceInfo = {
  references: [emptyReference],
  description: '^ Aicraft with miexed PIP and non-PIP engines\n# Values to be entered on the FMC.',
  suffixMap: {},
};

export type ApmReferenceHook = ReturnType<typeof useApmReference>;
function useApmReference(pageErrorState: [string, React.Dispatch<React.SetStateAction<string>>]) {
  const [newReference, setNewReference] = useState<APMReferenceInfo>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const setPageError = pageErrorState[1];

  async function submitReference({ acType, isRemove }: { acType: string; isRemove?: boolean }) {
    if (!newReference) return;
    setSubmitting(true);
    const data = await postApmDetailByAxios({
      acType,
      reference: isRemove ? undefined : newReference,
    }).catch((err) => {
      setPageError(`Error fetching data: ${err}`);
      throw err;
    });

    if (!data) return;
    setSubmitting(false);
    return data;
  }

  function updateReference(reference?: APMReferenceInfo) {
    setNewReference(reference ? { ...reference } : defaultReference);
  }

  function updateDescription(description: string) {
    setNewReference((prev) => {
      if (!prev) return prev;
      return { ...prev, description };
    });
  }

  function updateSuffixMap(key: string, value: string) {
    setNewReference((prev) => {
      if (!prev) return prev;
      return { ...prev, suffixMap: { ...prev.suffixMap, [key]: value } };
    });
  }

  function addReference() {
    setNewReference((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        references: [...prev.references, { ...emptyReference }],
      };
    });
  }

  function copyReference(index: number) {
    setNewReference((prev) => {
      if (!prev) return prev;
      const newReferences = [...prev.references];
      newReferences.splice(index, 0, { ...prev.references[index] });
      return { ...prev, references: newReferences };
    });
  }

  function deleteReference(index: number) {
    setNewReference((prev) => {
      if (!prev) return prev;
      const newReferences = [...prev.references];
      newReferences.splice(index, 1);
      return { ...prev, references: newReferences };
    });
  }

  function updateReferenceValue(index: number, key: keyof APMReference, value: string) {
    setNewReference((prev) => {
      if (!prev) return prev;
      const newReferences = [...prev.references];
      newReferences[index] = { ...newReferences[index], [key]: value };
      return { ...prev, references: newReferences };
    });
  }

  return {
    newReference,
    submitReference,
    updateReference,
    submitting,
    addReference,
    copyReference,
    deleteReference,
    updateReferenceValue,
    updateDescription,
    updateSuffixMap,
  };
}

export { useApm, useApmDetail, filterReferenceRegs, useApmReference };
