import axios from "axios";
import webConfig from '../config';
const { SERVER_BASE_URL, WHITE_LIST_API } = webConfig;

export interface DataResponse {
  owners: string[];
  actypes: Actype[];
  acregs: ACReg[];
}

export interface ACReg {
  acreg: string;
  actype: string;
  mtow: number;
  acpacks: string;
}

export interface Actype {
  value: string;
  desc: string;
}

export const getAcarsl0DataByAxios = async (
  owner: string = 'CX'
): Promise<any> => {
  const url = `${SERVER_BASE_URL}/acarsl0?owner=${owner}`;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const response = await axios.get(url, { headers });
  return response?.data;
};

export interface RequestParams {
  actype: string;
  acreg: string;
  icao: string;
  owner: string;
  fltnum: string;
}