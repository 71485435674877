import React, { useEffect } from 'react';
import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';

type CodeProps = {
  code?: number;
};

const PageError: FC<CodeProps & { refreshToken?: () => void }> = ({ code, refreshToken }) => {
  const prop = useParams();
  const location = useLocation();
  const errorMessage = new URLSearchParams(location.search).get('message');

  useEffect(() => {
    prop.code && prop.code === '504' && refreshToken && refreshToken();
  }, [code, errorMessage]);

  return (
    <div className='content'>
      Error message as below, please contact system admin.<br></br>
      <div>
        <label>ErrorCode:</label>
        <span>{prop.code}</span>
        <br />
        <span>ErrorMessage:{errorMessage}</span>
      </div>
    </div>
  );
};

export default PageError;
