import APM from '../../types/apm/APM';
import { getTableConfig, populateCustomData } from './apmTableConfigs';

const ApmTable = ({ apms, acType }: { apms: APM[]; acType: string }) => {
  const tableConfig = getTableConfig(acType);
  return (
    <table className='apm-table apm-table-main'>
      <thead>
        <tr>
          {tableConfig.map((config, i) => (
            <th key={`apm_${i}`} dangerouslySetInnerHTML={{ __html: config.label }}></th>
          ))}
        </tr>
      </thead>
      <tbody>
        {apms.map((apm, i) => {
          populateCustomData(apm);
          return (
            <tr key={`apm_${i}`}>
              {tableConfig.map((config) => (
                <td key={config.label} className={config.className}>
                  {config.display ? config.display(apm) : apm[config.key!]}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ApmTable;
