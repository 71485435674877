import React, { useEffect } from 'react';
import { FormFields } from './acars1';

interface RwyOffsetProps {
  p1State:FormFields
}

export const RwyOffset: React.FC<RwyOffsetProps> = ({p1State}) => (
  <div id='rwyoffset' style={{ visibility: 'visible' }}>
    Rwy Adhoc reduction (ft):
    <br />
    <select name='adhoc' defaultValue={p1State?.adhoc||""}>
      <option value=' '>Nil</option>
      {Array.from({ length: 30 }, (_, i) => i + 1).map((i) => (
        <option key={i} value={-i}>
          {i * 100}
        </option>
      ))}
    </select>
  </div>
);

interface ShowWindProps {}

export const ShowWind: React.FC<ShowWindProps> = () => (
  <tr>
    <td align='left'>
      <table>
        <tr>
          <td align='left'>
            <div id='headtail' style={{ visibility: 'visible' }}>
              WIND H/T(KTS): <br />
              <select name='wind' onChange={ResetMAGDIR}>
                <option value='   '>NA </option>
                {Array.from({ length: 20 }, (_, i) => 20 - i).map((i) => (
                  <option key={`h${i}`} value={`${i}H`}>
                    {`${i}H`}
                  </option>
                ))}
                <option value='0' selected>
                  0
                </option>
                {Array.from({ length: 15 }, (_, i) => i + 1).map((i) => (
                  <option key={`t${i}`} value={`${i}T`}>
                    {`${i}T`}
                  </option>
                ))}
              </select>
              <br />
              or
              <br />
            </div>
            <div id='windht' style={{ visibility: 'visible' }}>
              WIND DIR(°)/MAG (KTS):
              <br />
              <select name='winddir' onChange={ResetWindHT}>
                {Array.from({ length: 37 }, (_, i) => i * 10).map((i) => (
                  <option key={`dir${i}`} value={i}>
                    {i}
                  </option>
                ))}
              </select>{' '}
              /
              <select name='windmag' onChange={ResetWindHT}>
                {Array.from({ length: 41 }, (_, i) => i).map((i) => (
                  <option key={`mag${i}`} value={i}>
                    {i}
                  </option>
                ))}
              </select>
            </div>
          </td>
        </tr>
      </table>
    </td>
  </tr>
);

interface ShowMELProps {
  mel_options: string[];
}

export const ShowMEL: React.FC<ShowMELProps> = ({ mel_options }) => (
  <>
    <option value='N'>Normal</option>
    {mel_options.map((mel) => (
      <option key={mel} value={mel.slice(0, 15).trimEnd()}>
        {mel}
      </option>
    ))}
  </>
);

interface ShowCommonEndProps {}

export const ShowCommonEnd: React.FC<ShowCommonEndProps> = () => {
  return (
    <>
      <input type='submit' value='Submit' />
    </>
  );
};

interface Show777Props {
  icao: string;
  iata: string;
  actype: string;
  mtow: number;
  acpacks: string;
  rwylist: [string, string][];
  ai_options: string[];
  flap_options: string[];
  rating_options: string[];
  mel_options: string[];
  formData: FormFields | undefined;
  setFormData: (formData: FormFields) => void;
  p1State:FormFields
}

export const Show777: React.FC<Show777Props> = ({
  icao,
  iata,
  actype,
  mtow,
  acpacks,
  rwylist,
  ai_options,
  flap_options,
  rating_options,
  mel_options,
  formData,
  setFormData,
  p1State
}) => {
  useEffect(()=>{
    setFormData({
      ...formData, 
      qnh: p1State?.qnh||"", 
      depth: p1State?.depth||"", 
      agtow: p1State?.agtow||"", 
      oat: p1State?.oat||"", 
      wantiice: p1State?.wantiice||"", 
      cdl: p1State?.cdl||"", 
      
    }as FormFields)
  },[])
  return(
  <>
    <p>
      AIRPORT {icao} ({iata})
    </p>
    <table width='700'>
      <tr>
        <td align='left'>
          RUNWAY CODE
          <br />
          <select name='rwy' size={1} onChange={ChgRwy} defaultValue={p1State?.rwy||""}>
            {rwylist.map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
            <option value='LST'>LST (show runway distances)</option>
            <option value='ALL'>ALL (show runway codes)</option>
            <option value='ALL-RTOW'>RTOW for all runways</option>
          </select>
          {actype === '73ER' && (
            <p className='content'>
              <select name='acg' size={1}>
                <option value=' '>Default CG</option>
                <option value='F'>Forward CG</option>
                <option value='A'>Aft CG</option>
              </select>
              ACG option:
              <br />
              Default: ATOW {'>'} 280t: ACG1 else Forward CG
              <br />
              Aft : ACG2
            </p>
          )}
          <RwyOffset p1State={p1State}/>
        </td>
        <td align='right'>
          <br />
          RWY SURFACE <br />
          <select name='surface' onChange={Chg777Surface} defaultValue={p1State?.surface||""}>
            <option value='DRY'>DRY</option>
            <option value='WET'>WET</option>
            <option value='WT'>Standing Water</option>
            <option value='SH'>Slush</option>
            <option value='CSNW'>Compact Snow</option>
            <option value='SW'>Dry Snow</option>
            <option value='ICY'>Wet ICE</option>
          </select>
        </td>
      </tr>
      <tr>
        <td align='left'>
          QNH <br />
          <input
            type='text'
            name='qnh'
            value={formData?.qnh ||p1State?.qnh|| '1013'}
            size={4}
            style={{ textAlign: 'right' }}
            onChange={(event) =>
              setFormData({ ...formData, qnh: event.target.value } as FormFields)
            }
          />
          (hPa)
        </td>
        <td align='right'>
          <div id='contamdepth' style={{ visibility: 'hidden' }}>
            Contaminant Depth (mm)
            <br />
            <input
              type='text'
              name='depth'
              value={formData?.depth||p1State?.depth || ''}
              size={4}
              style={{ textAlign: 'right' }}
              onChange={(event) =>
                setFormData({ ...formData, depth: event.target.value } as FormFields)
              }
            />
            <br />
            <p className='content'>
              Slush/Standing Water: 2mm - 12mm
              <br />
              (1/4 in = 6mm; 1/2 in = 12mm)
              <br />
              Dry Snow: 2mm - 101mm
              <br />
              (1/2 in = 12mm; 2 in = 51mm)
            </p>
          </div>
        </td>
      </tr>
      <tr>
        <td align='left'>
          ACT TOW <br />
          <input
            type='text'
            name='agtow'
            value={formData?.agtow||p1State?.agtow || mtow}
            size={6}
            style={{ textAlign: 'right' }}
            onChange={(event) =>
              setFormData({ ...formData, agtow: event.target.value } as FormFields)
            }
          />
        </td>
        <td> </td>
      </tr>
      <tr>
        <td align='left'>
          OAT <br />
          <input
            type='text'
            name='oat'
            value={formData?.oat ||p1State?.oat|| '30'}
            size={4}
            style={{ textAlign: 'right' }}
            defaultValue={p1State?.oat||""}
            onChange={(event) =>
              setFormData({ ...formData, oat: event.target.value } as FormFields)
            }
          />
          °C
        </td>
        <td> </td>
      </tr>
      <tr>
        <td align='left'>
          <table>
            <tr>
              <td align='left'>
                <div id='headtail' style={{ visibility: 'visible' }}>
                  WIND H/T(KTS): <br />
                  <select name='wind' onChange={ResetMAGDIR} defaultValue={p1State?.wind||""}>
                    <option value='   '>NA </option>
                    {Array.from({ length: 20 }, (_, i) => 20 - i).map((i) => (
                      <option key={`h${i}`} value={`${i}H`}>
                        {`${i}H`}
                      </option>
                    ))}
                    <option value='0' selected>
                      0
                    </option>
                    {Array.from({ length: 15 }, (_, i) => i + 1).map((i) => (
                      <option key={`t${i}`} value={`${i}T`}>
                        {`${i}T`}
                      </option>
                    ))}
                  </select>
                  <br />
                  or
                  <br />
                </div>
                <div id='windht' style={{ visibility: 'visible' }}>
                  WIND DIR(°)/MAG (KTS):
                  <br />
                  <select name='winddir' onChange={ResetWindHT} defaultValue={p1State?.winddir||""}>
                    {Array.from({ length: 37 }, (_, i) => i * 10).map((i) => (
                      <option key={`dir${i}`} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>{' '}
                  /
                  <select name='windmag' onChange={ResetWindHT} defaultValue={p1State?.windmag||""}>
                    {Array.from({ length: 41 }, (_, i) => i).map((i) => (
                      <option key={`mag${i}`} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td align='left'>
          FLAPS <br />
          <select name='flap' size={1} defaultValue={p1State?.flap||""}>
            {flap_options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </td>
        <td valign='top' align='right'>
          ANTI ICE
          <select name='antiice' size={1} defaultValue={p1State?.antiice||""}>
            {ai_options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </td>
      </tr>
      <tr>
        <td valign='top' align='left'>
          RATING <br />
          <select name='rating' size={1} defaultValue={p1State?.rating||""}>
            {rating_options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </td>
        <td>
          <input type='hidden' name='wantiice' value='N' />
        </td>
      </tr>
      <tr>
        <td align='left'>
          {acpacks === 'O' ? (
            <>
              PACKS
              <br />
              <select name='packs' size={1} defaultValue={p1State?.packs||""}>
                <option value='OPT'>OPT</option>
                <option value='Y'>ON</option>
                <option value='N'>OFF</option>
              </select>
            </>
          ) : (
            <>
              PACKS ON <br />
              <select name='packs' size={1} defaultValue={p1State?.packs||""}>
                <option value='Y'>Y</option>
                <option value='N'>N</option>
              </select>
            </>
          )}
        </td>
        <td align='right'>
          MEL 1st
          <select name='mel1' size={1} defaultValue={p1State?.mel1||""}>
            <ShowMEL mel_options={mel_options} />
          </select>
        </td>
      </tr>
      <tr>
        <td> </td>
        <td align='right'>
          MEL 2nd
          <select name='mel2' size={1} defaultValue={p1State?.mel2||""}>
            <ShowMEL mel_options={mel_options} />
          </select>
        </td>
      </tr>
      <tr>
        <td> </td>
        <td align='right'>
          CDL/SP OPS
          <input
            type='text'
            name='cdl'
            value={formData?.cdl ||p1State?.cdl|| 'N'}
            size={9}
            style={{ textAlign: 'right' }}
            onChange={(event) =>
              setFormData({ ...formData, cdl: event.target.value } as FormFields)
            }
          />
        </td>
      </tr>
      <ShowCommonEnd />
    </table>
  </>
);}

interface ShowAirbusProps {
  actype: string;
  acpacks: string;
  ai_options: string[];
  eng_options: string[];
  wng_options: string[];
  mel_options: string[];
  formData: FormFields | undefined;
  setFormData: (formData: FormFields) => void;
  p1State:FormFields
}

export const ShowAirbus: React.FC<ShowAirbusProps> = ({
  actype,
  acpacks,
  ai_options,
  eng_options,
  wng_options,
  mel_options,
  formData,
  setFormData,
  p1State
}) => {
  const actypeList = [
    'A306',
    'L332',
    'L33Z',
    'L33B',
    'A300',
    'K330',
    'K333',
    'K33B',
    'A320',
    'A321',
    'A32N',
    'A32I',
  ];

  return (
    <>
      <tr>
        <td align='left'>
          QNH:
          <br />
          <input
            type='text'
            name='qnh'
            value={formData?.qnh ||p1State?.qnh|| '1013'}
            size={4}
            style={{ textAlign: 'right' }}
            onChange={(event) =>
              setFormData({ ...formData, qnh: event.target.value } as FormFields)
            }
          />{' '}
          hPa
        </td>
        <td align='right'>
          CONF
          <br />
          <select name='flap' size={1} defaultValue={p1State?.flap||""}>
            <option value='OPT'>OPT</option>
            {(['A306', 'A300'].includes(actype) && (
              <>
                <option value='00'>15/00</option>
                <option value='20'>15/20</option>
                <option value='15'>15/15</option>
              </>
            )) || (
              <>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
              </>
            )}
          </select>
        </td>
      </tr>
      <tr>
        <td align='left'>
          <table>
            <tr>
              <td align='left'>
                <div id='headtail' style={{ visibility: 'visible' }}>
                  WIND H/T(KTS): <br />
                  <select name='wind' onChange={ResetMAGDIR} defaultValue={p1State?.wind||""}>
                    <option value='   '>NA </option>
                    {Array.from({ length: 20 }, (_, i) => 20 - i).map((i) => (
                      <option key={`h${i}`} value={`${i}H`}>
                        {`${i}H`}
                      </option>
                    ))}
                    <option value='0' selected>
                      0
                    </option>
                    {Array.from({ length: 15 }, (_, i) => i + 1).map((i) => (
                      <option key={`t${i}`} value={`${i}T`}>
                        {`${i}T`}
                      </option>
                    ))}
                  </select>
                  <br />
                  or
                  <br />
                </div>
                <div id='windht' style={{ visibility: 'visible' }}>
                  WIND DIR(°)/MAG (KTS):
                  <br />
                  <select name='winddir' onChange={ResetWindHT} defaultValue={p1State?.winddir||""}>
                    {Array.from({ length: 37 }, (_, i) => i * 10).map((i) => (
                      <option key={`dir${i}`} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>{' '}
                  /
                  <select name='windmag' onChange={ResetWindHT} defaultValue={p1State?.windmag||""}>
                    {Array.from({ length: 41 }, (_, i) => i).map((i) => (
                      <option key={`mag${i}`} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>
              </td>
            </tr>
          </table>
        </td>
        <td align='right' valign='top'>
          RATING
          <br />
          <select name='rating' size={1} defaultValue={p1State?.rating||""}>
            <option value='OPT'>OPT</option>
            <option value='TOGA'>TOGA</option>
            {!actypeList.includes(actype) && (
              <>
                <option value='D04'>D04 </option>
                <option value='D08'>D08 </option>
                <option value='D12'>D12 </option>
                <option value='D16'>D16 </option>
                <option value='D20'>D20 </option>
                <option value='D24'>D24 </option>
              </>
            )}
          </select>
        </td>
      </tr>
      <ShowCommon2
        actype={actype}
        acpacks={acpacks}
        ai_options={ai_options}
        eng_options={eng_options}
        wng_options={wng_options}
        mel_options={mel_options}
        formData={formData}
        setFormData={setFormData}
        p1State={p1State}
      />
      <ShowCommonEnd />
    </>
  );
};

interface ShowCommonProps {
  actype: string;
  mtow: number;
  rwylist: [string, string][];
  surface_options: string[];
  formData: FormFields | undefined;
  setFormData: (formData: FormFields) => void;
  p1State:FormFields
}

export const ShowCommon: React.FC<ShowCommonProps> = ({
  actype,
  mtow,
  rwylist,
  surface_options,
  formData,
  setFormData,
  p1State
}) => {
  const list400 = ['400G', '40FG', '40PW', '4ERF', '7478'];

  console.log("LOG - p1-1 OAT value:",p1State?.oat||"null")
  return (
    <>
      <tr>
        <td align='left'>
          RWY CODE: <br />
          <select name='rwy' size={1} onChange={ChgRwy} defaultValue={p1State?.rwy||""}>
            {rwylist.map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
            <option value='LST'>LST (show runway distances)</option>
            <option value='ALL'>ALL (show runway codes)</option>
            {!['A320', 'A321', 'A32I'].includes(actype) && (
              <option value='ALL-RTOW'>RTOW for all runways</option>
            )}
          </select>
          <br />
          <RwyOffset p1State={p1State}/>
        </td>
        <td align='right'>
          ACT TOW
          <br />
          <input
            type='text'
            name='agtow'
            value={formData?.agtow||p1State?.agtow || mtow}
            size={5}
            style={{ textAlign: 'right' }}
            onChange={(event) =>
              setFormData({ ...formData, agtow: event.target.value } as FormFields)
            }
          />
        </td>
      </tr>
      <tr>
        <td align='left'>
          OAT
          <br />
          <input
            type='text'
            name='oat'
            // value={p1State?.oat||formData?.oat || '30'}
            value={formData?.oat ||p1State?.oat|| '30'}
            size={4}
            style={{ textAlign: 'right' }}
            defaultValue={p1State?.oat||""}
            onChange={(event) =>
              setFormData({ ...formData, oat: event.target.value } as FormFields)
            }
          />
          °C
        </td>
        <td align='right'>
          RWY SURFACE
          <br />
          {list400.includes(actype) ? (
            <>
              <input
                type='text'
                name='surface'
                value={formData?.surface ||p1State?.surface|| 'DRY'}
                size={4}
                style={{ textAlign: 'right' }}
                onChange={(event) =>
                  setFormData({ ...formData, surface: event.target.value } as FormFields)
                }
              />
              <br />
              <p className='content'>
                {(['400G', '40FG'].includes(actype) && (
                  <>
                    DRY, WET, CSNW, ICY
                    <br />
                    Standing Water:SW3 to SW13
                    <br />
                    Slush:SH3 to SH13
                    <br />
                    Wet Snow:WS3 to WS13
                    <br />
                    Dry Snow:DS13 to DS51
                    <br />
                  </>
                )) ||
                  (actype === '40PW' && (
                    <>
                      DRY, WET, CSNW, ICY
                      <br />
                      Standing Water:SW3 to SW12
                      <br />
                      Slush:SH3 to SH12
                      <br />
                      Wet Snow:WS3 to WS12
                      <br />
                      Dry Snow:DS12 to DS50
                      <br />
                    </>
                  )) ||
                  (actype === '4ERF' && (
                    <>
                      DRY, WET, CSNW, ICY
                      <br />
                      Standing Water:SW2 to SW12
                      <br />
                      Slush:SH2 to SH12
                      <br />
                      Wet Snow:WS2 to WS12
                      <br />
                      Dry Snow:DS2 to DS99
                      <br />
                    </>
                  )) ||
                  (actype === '7478' && (
                    <>
                      DRY, WET, CSNW, ICY
                      <br />
                      Standing Water:SW1 to SW15
                      <br />
                      Slush:SH1 to SH15
                      <br />
                      Wet Snow:WS1 to WS30
                      <br />
                      Dry Snow:DS1 to DS99
                      <br />
                    </>
                  ))}
              </p>
              <br />
            </>
          ) : (
            <select name='surface' size={1} defaultValue={p1State?.surface||""}>
              {surface_options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}
        </td>
      </tr>
    </>
  );
};

interface Show400Props {
  actype: string;
  acpacks: string;
  flap_options: string[];
  rating_options: string[];
  ai_options: string[];
  eng_options: string[];
  wng_options: string[];
  mel_options: string[];
  formData: FormFields | undefined;
  setFormData: (formData: FormFields) => void;
  p1State:FormFields
}

export const Show400: React.FC<Show400Props> = ({
  actype,
  acpacks,
  flap_options,
  rating_options,
  ai_options,
  eng_options,
  wng_options,
  mel_options,
  formData,
  setFormData,
  p1State
}) => {
  
  return(
  <>
    <tr>
      <td align='left'>
        QNH:
        <br />
        <input
          type='text'
          name='qnh'
          value={formData?.qnh ||p1State?.qnh|| '1013'}
          size={4}
          style={{ textAlign: 'right' }}
          onChange={(event) => setFormData({ ...formData, qnh: event.target.value } as FormFields)}
        />{' '}
        hPa
      </td>
      <td align='right'>
        FLAPS :
        <br />
        <select name='flap' size={1} defaultValue={p1State?.flap||""}>
          {flap_options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </td>
    </tr>
    <tr>
      <td align='left'>
        <table>
          <tr>
            <td align='left'>
              <div id='headtail' style={{ visibility: 'visible' }}>
                WIND H/T(KTS): <br />
                <select name='wind' onChange={ResetMAGDIR} defaultValue={p1State?.wind||""}>
                  <option value='   '>NA </option>
                  {Array.from({ length: 20 }, (_, i) => 20 - i).map((i) => (
                    <option key={`h${i}`} value={`${i}H`}>
                      {`${i}H`}
                    </option>
                  ))}
                  <option value='0' selected>
                    0
                  </option>
                  {Array.from({ length: 15 }, (_, i) => i + 1).map((i) => (
                    <option key={`t${i}`} value={`${i}T`}>
                      {`${i}T`}
                    </option>
                  ))}
                </select>
                <br />
                or
                <br />
              </div>
              <div id='windht' style={{ visibility: 'visible' }}>
                WIND DIR(°)/MAG (KTS):
                <br />
                <select name='winddir' onChange={ResetWindHT} defaultValue={p1State?.winddir||""}>
                  {Array.from({ length: 37 }, (_, i) => i * 10).map((i) => (
                    <option key={`dir${i}`} value={i}>
                      {i}
                    </option>
                  ))}
                </select>{' '}
                /
                <select name='windmag' onChange={ResetWindHT} defaultValue={p1State?.windmag||""}>
                  {Array.from({ length: 41 }, (_, i) => i).map((i) => (
                    <option key={`mag${i}`} value={i}>
                      {i}
                    </option>
                  ))}
                </select>
              </div>
            </td>
          </tr>
        </table>
      </td>
      <td align='right' valign='top'>
        RATING :
        <br />
        <select name='rating' size={1} defaultValue={p1State?.rating||""}>
          {rating_options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </td>
    </tr>
    <ShowCommon2
      actype={actype}
      acpacks={acpacks}
      ai_options={ai_options}
      eng_options={eng_options}
      wng_options={wng_options}
      mel_options={mel_options}
      formData={formData}
      setFormData={setFormData}
      p1State={p1State}
    />
    <ShowCommonEnd />
  </>
);}

interface ShowCommon2Props {
  actype: string;
  acpacks: string;
  ai_options: string[];
  eng_options: string[];
  wng_options: string[];
  mel_options: string[];
  formData: FormFields | undefined;
  setFormData: (formData: FormFields) => void;
  p1State:FormFields
}

export const ShowCommon2: React.FC<ShowCommon2Props> = ({
  actype,
  acpacks,
  ai_options,
  eng_options,
  wng_options,
  mel_options,
  formData,
  setFormData,
  p1State
}) => {
  const list400 = ['400G', '40FG', '40PW', '4ERF', '7478'];

  return (
    <>
      <tr>
        <td colSpan={2}>
          <hr />
        </td>
      </tr>
      <tr>
        <td align='left'>
          PACKS
          <br />
          <select name='packs' size={1} defaultValue={p1State?.packs||""}>
            {acpacks === 'Y' ? (
              <>
                <option value='Y'>Y (ON)</option>
                <option value='N'>N (OFF)</option>
              </>
            ) : acpacks === 'N' ? (
              <>
                <option value='N'>N (OFF)</option>
                <option value='Y'>Y (ON)</option>
              </>
            ) : (
              <>
                <option value='OPT'>OPT</option>
                <option value='Y'>Y (ON)</option>
                <option value='N'>N (OFF)</option>
              </>
            )}
          </select>
        </td>
        <td align='right'>
          MEL-1
          <br />
          <select name='mel1' size={1} defaultValue={p1State?.mel1||""}>
            <ShowMEL mel_options={mel_options} />
          </select>
        </td>
      </tr>
      <tr>
        <td>
          {list400.includes(actype) ? (
            <>
              NAC AI
              <br />
              <select name='antiice' size={1} defaultValue={p1State?.antiice||""}>
                {ai_options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </>
          ) : (
            <>
              ENG AI
              <br />
              <select name='antiice' size={1} defaultValue={p1State?.antiice||""}>
                {eng_options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </>
          )}
        </td>
        <td align='right'>
          MEL-2
          <br />
          <select name='mel2' size={1} defaultValue={p1State?.mel2||""}>
            <ShowMEL mel_options={mel_options} />
          </select>
        </td>
      </tr>
      <tr>
        <td>
          {list400.includes(actype) ? (
            <input type='hidden' name='wantiice' value='N' />
          ) : (
            <>
              WING AI
              <br />
              <select name='wantiice' size={1} defaultValue={p1State?.wantiice||""}>
                {wng_options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </>
          )}
        </td>
        <td align='right'>
          CDL/SP OPS
          <br />
          <input
            type='text'
            name='cdl'
            value={formData?.cdl ||p1State?.cdl|| 'N'}
            size={9}
            style={{ textAlign: 'right' }}
            onChange={(event) =>
              setFormData({ ...formData, cdl: event.target.value } as FormFields)
            }
          />
        </td>
      </tr>
    </>
  );
};

const ResetWindHT = () => {
  document.querySelector<HTMLSelectElement>('select[name="wind"]')!.options[0].selected = true;
};

const ResetMAGDIR = () => {
  document.querySelector<HTMLSelectElement>('select[name="winddir"]')!.options[0].selected = true;
  document.querySelector<HTMLSelectElement>('select[name="windmag"]')!.options[0].selected = true;
};

const ChgRwy = () => {
  const selectedRwy = document.querySelector<HTMLSelectElement>('select[name="rwy"]')?.value;
  switch (selectedRwy) {
    case 'ALL-RTOW':
      document.querySelector<HTMLSelectElement>('select[name="wind"]')!.options[0].selected = true;
      document.querySelector<HTMLSelectElement>('select[name="adhoc"]')!.options[0].selected = true;
      document.getElementById('headtail')!.style.visibility='hidden';
      document.getElementById('windht')!.style.visibility='visible';
      document.getElementById('rwyoffset')!.style.visibility='hidden';
      const actype = (document.querySelector('input[name="actype"]') as HTMLInputElement).value;
      if (actype === 'B772' || actype === 'B784' || actype === '73ER') {
        const packsSelect = document.querySelector<HTMLSelectElement>('select[name="packs"]');
        if (packsSelect?.value)
          document.querySelector<HTMLSelectElement>('select[name="rating"]')!.options[1].selected = true;
          if (packsSelect && packsSelect.options.length > 2) {
            packsSelect.options[2].selected = true;
          }
      }
      break;
    case 'ALL':
    case 'LST':
      document.querySelector<HTMLSelectElement>('select[name="wind"]')!.options[0].selected = true;
      document.querySelector<HTMLSelectElement>('select[name="adhoc"]')!.options[0].selected = true;
      document.getElementById('headtail')!.style.visibility='hidden';
      document.getElementById('windht')!.style.visibility='hidden';
      document.getElementById('rwyoffset')!.style.visibility='hidden';
      break;
    default:
      document.getElementById('headtail')!.style.visibility='visible';
      document.getElementById('windht')!.style.visibility='visible';
      document.getElementById('rwyoffset')!.style.visibility='visible';
      break;
  }
};

const Chg777Surface = () => {
  const selectedSurface =
    document.querySelector<HTMLSelectElement>('select[name="surface"]')?.value;
  switch (selectedSurface) {
    case 'SH':
    case 'SW':
    case 'WT':
      document.getElementById('contamdepth')!.style.visibility='visible';
      break;
    default:
      document.getElementById('contamdepth')!.style.visibility='hidden';
      break;
  }
};
