import _, { unescape } from 'lodash';
import moment from 'moment-timezone';
import env from '../site-common/env';

export const isActive = (startDate: Date | string, endDate: Date | string): boolean => {
  var today = moment();
  return today.isSame(startDate, 'day') ||
    today.isBetween(startDate, endDate, 'day') ||
    today.isSame(endDate, 'day')
    ? true
    : false;
};

export const isExpired = (endDate: Date | string): boolean => {
  var today = moment();
  return today.isAfter(endDate, 'day') ? true : false;
};

export const convertDateToDate = (value: string, format?: string) => {
  return value && format && moment(value, format, true).isValid()
    ? moment(value, format).toDate()
    : '';
};

export const convertByteToMb = (bytes: number) => bytes / 1024 / 1024;

export const sanitizeCriteria = (criteria?: Record<string, any>) => {
  if (!criteria) return undefined;

  let result: Record<string, any> = {};

  Object.keys(criteria).forEach((k) => {
    if (criteria[k])
      result[k] = moment.isDate(criteria[k]) ? convertDate(criteria[k]) : criteria[k].trim();
  });

  return result;
};

export const convertToDisplayDateTime = (date: any) => {
  return date ? (moment(date).isValid() ? moment(date).format(env.DATE_TIME_FORMAT) : '') : '';
};

export const convertToDisplayDate = (date: any) => {
  return date ? (moment(date).isValid() ? moment(date).format(env.DATE_FORMAT) : '') : '';
};

export const convertDate = (date: any) => {
  return moment(date).format('DD-MM-YYYY');
};

export const convertRequestBodyDate = (date: any) => {
  return moment(date).format('YYYY-MM-DD');
};

export const convertNumber = (input: any, defaultValue: any = null) => {
  return input != null && input != undefined
    ? isNaN(Number(input))
      ? input
      : Number(input)
    : defaultValue;
};

export const getLastPageOffset = (totalElements: number, pageSize: number) =>
  Math.max(Math.floor((totalElements - 1) / pageSize) * pageSize, 0);

export const distinctBy = <T extends unknown>(array: T[], key: keyof T) => {
  return array.reduce(
    (unique, item) => (unique.find((u) => u[key] === item[key]) ? unique : [...unique, item]),
    [] as T[]
  );
};

export const distinctByNested = <T extends unknown, A extends keyof T>(
  array: T[],
  key: A,
  nestedKey: keyof T[A]
) => {
  return array.reduce(
    (unique, item) =>
      unique.find((u) => u[key][nestedKey] === item[key][nestedKey]) ? unique : [...unique, item],
    [] as T[]
  );
};

export const sortBy = <T extends unknown>(array: T[], key: string, order: string) => {
  const compareValue = order == 'asc' ? 1 : -1;
  return array.sort((obj1: any, obj2: any) =>
    obj1[key].toLowerCase() > obj2[key].toLowerCase()
      ? compareValue
      : obj1[key].toLowerCase() < obj2[key].toLowerCase()
        ? -compareValue
        : 0
  );
};

export const sortByNested = <T extends unknown, A extends keyof T>(
  array: T[],
  key: A,
  nestedKey: keyof T[A],
  order: string
) => {
  const compareValue = order == 'asc' ? 1 : -1;
  return array.sort((obj1: any, obj2: any) =>
    obj1[key][nestedKey]?.toLowerCase() > obj2[key][nestedKey]?.toLowerCase()
      ? compareValue
      : obj1[key][nestedKey]?.toLowerCase() < obj2[key][nestedKey]?.toLowerCase()
        ? -compareValue
        : 0
  );
};

export const mapCamelCaseToSpacedLowerCase = (value: string) => {
  return value.replace(/([A-Z])/g, ' $1').toLocaleLowerCase();
};

export const getCSVDownload = (blob: Blob, fileName: string) => {
  fileName = fileName + moment().format('YYYYMMDDHHmmss') + '.csv';

  // For IE
  // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //     window.navigator.msSaveOrOpenBlob(blob, fileName);
  //     return;
  // }
  const url = window.URL.createObjectURL(new Blob([blob], { type: 'text/csv' }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  setTimeout(() => {
    link.remove();
    URL.revokeObjectURL(url);
  }, 1000);
};

export const convertFileToString = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target?.result as string);
    };
    reader.readAsText(file);
  });
};

export const flightDelayDurationFormat = (delayDuration?: number) => {
  if (delayDuration) {
    let HH = Math.trunc(delayDuration / 60);
    let MIN = delayDuration % 60;
    return (HH < 10 ? '0' + HH : HH) + ':' + (MIN < 10 ? '0' + MIN : MIN);
  }
  return null;
};

export const departureOrArrivaltimeFormat = (time?: string) => {
  let formatedTime = time && time.length > 0 ? time.slice(11, 16) : '--';
  return formatedTime;
};

export const depOrArrtimeFormat = (time: string): string => {
  const formatedTime = time.slice(0, 1) + ' ' + time.slice(1, 3) + ':' + time.slice(3);
  return formatedTime;
};

export const scheduledFlightDateFormat = (date: string): string => {
  return moment(date).format('DD MMM YYYY');
};

export const getCookie = (name: string): string | null => {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
};

export const commonHandleToUpperCase: any = (t: any) => {
  t.target.value = t.target.value.toUpperCase();
};

export const commonDisplayLongStr: any = (input: string, maxLen?: number): string => {
  if (maxLen == undefined || maxLen == null) maxLen = MAX_DISPLAY_FEILD_LEN;

  if (input == undefined || input == null) {
    return input;
  }
  if (input.length > maxLen) {
    var tmp: string = input.substring(0, maxLen - 1) + '...';
    return tmp;
  }
  return input;
};

export const removeNulls: any = (obj: any) => {
  return (function prune(current) {
    _.forOwn(current, function (value, key) {
      if (
        _.isUndefined(value) ||
        _.isNull(value) ||
        _.isNaN(value) ||
        (_.isString(value) && _.isEmpty(value)) ||
        (_.isObject(value) && _.isEmpty(prune(value)))
      ) {
        delete current[key];
      }
    });
    // remove any leftover undefined values from the delete
    // operation on an array
    if (_.isArray(current)) _.pull(current, undefined);

    return current;
  })(_.cloneDeep(obj)); // Do not modify the original object, create a clone instead
};

export const unescapeJsonValues: any = (obj: any) => {
  return (function prune(current) {
    _.forOwn(current, function (value, key) {
      if (_.isString(value) && !_.isEmpty(value)) {
        current[key] = _.unescape(value);
      } else if (_.isObject(value) && !_.isEmpty(value)) {
        prune(value);
      }
    });
    return current;
  })(_.cloneDeep(obj)); // Do not modify the original object, create a clone instead
};

export const MAX_DISPLAY_FEILD_LEN: number = 25;

export const isTokenExpired = (idToken: string): boolean => {
  const decode = JSON.parse(atob(idToken.split('.')[1]));
  return (decode.exp * 1000 < new Date().getTime())
};

export function clearTokenInSessionStorage() {
  // Get all keys from session storage
  const keys = Object.keys(sessionStorage);
  // Iterate over the keys
  keys.forEach((key) => {
    // Check if key contains "accesstoken" or "idtoken"
    if (key.includes("accesstoken") || key.includes("idtoken")) {
      // Remove the key from session storage
      sessionStorage.removeItem(key);
    }
    if (key.includes("msal")) {
      var token_str = sessionStorage.getItem(key)
      if (token_str) {
        var token = JSON.parse(token_str)
        if ("refreshToken" in token) {
          token['refreshToken'] = []
        }
        if ("accessToken" in token) {
          token['accessToken'] = []
        }
        sessionStorage.setItem(key, JSON.stringify(token))
      }

    }
  });
}