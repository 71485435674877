import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { RequestParams, postAcarsl1DataByAxios } from '../../services/acarsl1';
import AcarsForm, { AcarsFormData, AcarsFormProps } from './acarsl1-1';
import { on } from 'events';
import { Helmet } from 'react-helmet';
interface LandingData {
  actype: string;
  iata: string;
  icao: string;
  acreg: string;
  fltnum: string;
  isb747: boolean;
  isb777: boolean;
  iselev: boolean;
  isairbus: boolean;
  header: string;
  acmldw: string;
  rwylist: [string, string][];
  AirbusPage: React.ReactNode;
  B747Page: React.ReactNode;
  B777Page: React.ReactNode;
}
const Acarsl1: React.FC = () => {
  const [landingData, setLandingData] = useState<AcarsFormProps | null>(null);
  const [pageError, setPageError] = useState<string>('');
  const location = useLocation();
  if (!location.state) {
    return <Navigate to={`${(window as any)._navbase}/acarsl0`} replace/>;
  }
  const {state,p1State} = location.state ;
  const navigate = useNavigate();
  useEffect(() => {
    console.log("LOG - P1 state:",state)
    console.log("LOG - P1 p1State:",p1State)
    const fetchLandingData = async () => {
      try {
        const response = await postAcarsl1DataByAxios(state).catch((error) => {
          console.error('Error fetching data:', error);
          setPageError(error.message);
        });
        console.log("LOG - reponse",response);
        setLandingData(response);
      } catch (error) {
        console.error('Error fetching landing data:', error);
      }
    };
    state && fetchLandingData();
  }, []);
  const handleSubmit = (e: AcarsFormData) => {
    console.log("LOG - p1 e: ",e);
    navigate(`${(window as any)._navbase}/acarsl2`, { state: {"state":state,"p1State":e} });
  };
  const handleGoBack = (e: React.FormEvent) => {
    e.preventDefault();
    navigate(`${(window as any)._navbase}/acarsl0`, { state: state });
  };
  return (
    <>
      <Helmet>
        <title>{landingData ? `LAND Page 1` : 'Loading...'}</title>
        <meta name='description' content='LAND Application Page' />
      </Helmet>
      <div><button onClick={handleGoBack} style={{borderRadius:"3px",borderWidth:"0.5px", fontSize:"12pt"}}>Back</button></div>
      {landingData ? (
        <>
          <AcarsForm {...landingData} onSubmit={handleSubmit} prev={p1State}/>
        </>
      ) : pageError ? (
        pageError
      ) : (
        'Loading...'
      )}
    </>
  );
};
export default Acarsl1;