import axios from 'axios';
import webConfig from '../config';
import APM from '../types/apm/APM';
import APMPref from '../types/apm/APMPref';
import { APMReferenceInfo } from '../types/apm/APMReference';
const { SERVER_BASE_URL, WHITE_LIST_API } = webConfig;

type ApmData = {
  status: string;
  types: string[];
  apm_data: APM[];
};

type ApmDetailData = {
  status: string;
  reference: APMReferenceInfo;
  prefs: APMPref[];
};

type ApmDetailUpdateRequest = {
  acType: string;
  reference?: APMReferenceInfo;
};

export const getApmDataByAxios = async (): Promise<ApmData> => {
  const url = `${SERVER_BASE_URL}/apm`;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const response = await axios.get(url, { headers });
  return response?.data as ApmData;
};

export const getApmDetailByAxios = async (params: { actype: string }): Promise<ApmDetailData> => {
  const url = `${SERVER_BASE_URL}/apm/detail`;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const response = await axios.get(url, { headers, params });
  return response?.data;
};

export const postApmDetailByAxios = async (body: ApmDetailUpdateRequest): Promise<any> => {
  const url = `${SERVER_BASE_URL}/apm/detail`;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const response = await axios.post(url, body, { headers });
  return response?.data;
};
