import { APMReference } from '../../types/apm/APMReference';

type ColumnConfig = {
  label: string;
  key: keyof APMReference;
  display?: (reference: APMReference) => any;
  className?: string;
};

const TYPE: ColumnConfig = { label: 'Type', key: 'Type' };
const REG: ColumnConfig = { label: 'Registration', key: 'Reg' };
const CLIMB_ADJUSTED: ColumnConfig = { label: 'CLIMB', key: 'Climb' };
const FF_ADJUSTED: ColumnConfig = { label: 'F-F', key: 'FF' };
const DRAG_ADJUSTED: ColumnConfig = { label: 'DRAG', key: 'Drag' };
const PERF_ADJUSTED: ColumnConfig = { label: 'PERF', key: 'Perf' };
const IDLE: ColumnConfig = { label: 'IDLE', key: 'Idle' };
const ISSUE_DATE: ColumnConfig = {
  label: 'Last Issue Date<br/>YYYY-MM-DD',
  key: 'IssueDate',
};
const ENG_CHANGE_DATE: ColumnConfig = {
  label: 'Last Eng Change Date<br/>YYYY-MM-DD',
  key: 'EngChgDate',
};

const tableConfigTypeA: ColumnConfig[] = [
  TYPE,
  REG,
  CLIMB_ADJUSTED,
  PERF_ADJUSTED,
  IDLE,
  ISSUE_DATE,
  ENG_CHANGE_DATE,
];

const tableConfigTypeA300: ColumnConfig[] = [
  TYPE,
  REG,
  CLIMB_ADJUSTED,
  PERF_ADJUSTED,
  ISSUE_DATE,
  ENG_CHANGE_DATE,
];

const tableConfigTypeB: ColumnConfig[] = [
  TYPE,
  REG,
  CLIMB_ADJUSTED,
  FF_ADJUSTED,
  DRAG_ADJUSTED,
  PERF_ADJUSTED,
  ISSUE_DATE,
  ENG_CHANGE_DATE,
];

function getTableConfig(acType: string) {
  if (acType.startsWith('A300')) {
    return tableConfigTypeA300;
  } else if (acType.startsWith('A')) {
    return tableConfigTypeA;
  } else if (acType.startsWith('B')) {
    return tableConfigTypeB;
  }
  console.warn('config not found for acType:', acType);
  return [];
}

export type { ColumnConfig };
export { getTableConfig };
