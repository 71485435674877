import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useApmDetail, useApmReference } from '../../hooks/apm/apm';
import Loading from '../../components/Loading';
import ApmReferenceTable from '../../components/apm/ApmReferenceTable';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ApmReferenceEdit from '../../components/apm/ApmReferenceEdit';

const Reference: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const actype = searchParams.get('actype');
  if (!actype) {
    return <Navigate to={`${(window as any)._navbase}/apm`} />;
  }
  const pageErrorState = useState<string>('');
  const [pageError] = pageErrorState;
  const { apmReferenceInfo, fetchApmDetailData, complete } = useApmDetail(pageErrorState);
  const apmReferenceHook = useApmReference(pageErrorState);
  const { newReference, submitReference, updateReference, submitting } = apmReferenceHook;

  const saveHandler = async () => {
    if (!window.confirm(`Confirm to update the yellow table of ${actype}?`)) return;

    submitReference({ acType: actype })
      .then(() => {
        navigate(`${(window as any)._navbase}/apm?actype=${actype}`);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const removeHandler = async () => {
    if (
      !window.confirm(
        `Confirm to **REMOVE** the yellow table of ${actype}?\nYou cannot undo this action.`
      )
    )
      return;

    submitReference({ acType: actype, isRemove: true })
      .then(() => {
        navigate(`${(window as any)._navbase}/apm?actype=${actype}`);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const backHandler = () => {
    navigate(`${(window as any)._navbase}/apm?actype=${actype}`);
  };

  useEffect(() => {
    fetchApmDetailData(actype);
  }, []);

  useEffect(() => {
    updateReference(apmReferenceInfo);
  }, [complete]);

  return (
    <div className='apm-reference-page'>
      <Helmet>
        <title>{`APM Reference Page`}</title>
        <meta name='description' content='APM Reference Page' />
      </Helmet>
      <Loading complete={complete && !submitting} error={pageError}>
        <h1>APM - Edit Yellow Table: {actype}</h1>

        {newReference && (
          <ApmReferenceEdit
            acType={actype}
            apmReferenceInfo={newReference}
            apmReferenceHook={apmReferenceHook}
          />
        )}

        <br />

        <button
          style={{
            borderRadius: '3px',
            borderWidth: '0.5px',
            fontSize: '12pt',
            marginRight: '10px',
          }}
          onClick={saveHandler}
        >
          Save
        </button>
        <button
          style={{
            borderRadius: '3px',
            borderWidth: '0.5px',
            fontSize: '12pt',
            marginRight: '10px',
            color: 'red',
          }}
          onClick={removeHandler}
        >
          Remove
        </button>

        <br />
        <br />

        <h5>Edition Preview:</h5>
        {newReference && <ApmReferenceTable acType={actype} apmReferenceInfo={newReference} />}

        <br />

        <h5>Current yellow table:</h5>
        {apmReferenceInfo && (
          <ApmReferenceTable acType={actype} apmReferenceInfo={apmReferenceInfo} />
        )}
        {!apmReferenceInfo && <p>Yellow table is not set for {actype}.</p>}
      </Loading>

      <br />

      <button
        style={{ borderRadius: '3px', borderWidth: '0.5px', fontSize: '12pt' }}
        onClick={backHandler}
      >
        Back without saving
      </button>
    </div>
  );
};

export default Reference;
