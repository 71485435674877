import React, { useEffect, useState } from 'react';
import { AcarsFormData } from './acarsl1-1';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { postAcarsl2DataByAxios } from '../../services/acarsl2';
import { Helmet } from 'react-helmet';
import Paragraph from 'antd/es/typography/Paragraph';
interface Props {
  result: string[];
  timestamp: string;
}
const Acarsl2: React.FC = () => {
  const location = useLocation();
  if (!location.state) {
    return <Navigate to={`${(window as any)._navbase}/acarsl0`} replace/>;
  }
  const {state,p1State} = location.state ;
  const [pageError, setPageError] = useState<string>('');
  const [props, setProps] = React.useState<Props>();
  useEffect(() => {
    console.log("LOG - P2 e:", p1State)
    console.log("LOG - P2 state:",state)
    const fetchLandingData = async () => {
      try {
        const response = await postAcarsl2DataByAxios({
          ...p1State,
          slope: p1State.slope || '--------',
          fail2: p1State.fail2 || '',
          fail3: p1State.fail3 || '',
          lda: p1State.lda || '',
          athr: p1State.athr || 'N',
          aice: p1State.aice || '',
        }).catch((error) => {
          console.error('Error fetching data:', error);
          setPageError(error.message);
        });
        setProps(response);
      } catch (error) {
        console.error('Error fetching landing data:', error);
      }
    };
    p1State && fetchLandingData();
  }, []);
  
  const navigate = useNavigate()
  const handleGoBack = (e: React.FormEvent) => {
    e.preventDefault();
    navigate(`${(window as any)._navbase}/acarsl1`,{state:{"state":state,"p1State":p1State}});
  };
  return (
    <>
      <Helmet>
        <title>{props ? `LAND Page 2` : 'Loading...'}</title>
        <meta name='description' content='LAND Application Page' />
      </Helmet>
      <div><button onClick={handleGoBack} style={{borderRadius:"3px",borderWidth:"0.5px", fontSize:"12pt"}}>Back</button></div>
      {props ? (
        <div>
          <Paragraph style={{ whiteSpace: 'pre', fontFamily: 'monospace' }}>
            {props?.result}
          </Paragraph>
        </div>
      ) : pageError ? (
        pageError
      ) : (
        'Processing...'
      )}
    </>
  );
};
export default Acarsl2;