import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { getAcars0DataByAxios,RequestParams } from '../../services/acars0';
import { useLocation, useNavigate } from 'react-router-dom';
interface DataResponse {
  owners: string[];
  actypes: Actype[];
  acregs: string[];
}
interface ACReg {
  acreg: string;
  actype: string;
  mtow: number;
  acpacks: string;
}
interface Actype {
  value: string;
  desc: string;
}
const Acars0: React.FC = () => {
  const location = useLocation();
  const state = location.state as RequestParams;
  const [data, setData] = useState<DataResponse | null>(null);
  const [acregs, setACRegs] = useState<ACReg[]>([]);
  const [selectedActype, setSelectedActype] = useState<string>(state?.actype||"");
  const [selectedOwner, setSelectedOwner] = useState<string>(state?.owner||"CX");
  const [pageError, setPageError] = useState<string>('');
  const [firstLoading, setFirstLoading] = useState<Boolean>(true);
  useEffect(() => {
    const fetchData = async () => {
      var result:any;
      if(state!=null&&firstLoading){
        result = await getAcars0DataByAxios(state.owner).catch((error) => {
          console.error('Error fetching data:', error);
          setPageError(error.message);
        });
      }
      else {result = await getAcars0DataByAxios(selectedOwner).catch((error) => {
        console.error('Error fetching data:', error);
        setPageError(error.message);
      });}
      result && setData(result);
      result && setACRegs(result.acregs);
      
      if(state!=null&&firstLoading){
        console.log("First Loading!!!")
        console.log("LOG - P1 state:",state)
        setFirstLoading(false)
      }
      else if (result && result.actypes.length > 0) {
        //update AcType & AcReg to the first option in the list everytime change the Owner
        console.log("LOG - reset setSelectedActype")
        setSelectedActype(result.actypes[0].value);
        setActypeRef(result.actypes[0].value);
        const acregList =  result.acregs.filter((acreg:any) => {
          const index = result.actypes[0].value.indexOf('$');
          let actype1 = acreg.actype;
    
          if (index > 0) {
            actype1 = actype1.slice(0, index) + "$" + actype1.slice(index + 1);
          }
    
          return actype1 === result.actypes[0].value || (result.actypes[0].value === 'A321' && actype1 === 'A32I');
        });
        setAcregRef(acregList[0].acreg);
      };
    };
    selectedOwner && fetchData();
  }, [selectedOwner]);
  const handleActypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log("LOG - handleActypeChange")
    setSelectedActype(event.target.value);
    setActypeRef(event.target.value);
    const acregList =  acregs.filter((acreg) => {
      const index = event.target.value.indexOf('$');
      let actype1 = acreg.actype;
      if (index > 0) {
        actype1 = actype1.slice(0, index) + "$" + actype1.slice(index + 1);
      }
      return actype1 === event.target.value || (event.target.value === 'A321' && actype1 === 'A32I');
    });
    setAcregRef(acregList[0].acreg);
  };
  const handleAcRegChange=(event: React.ChangeEvent<HTMLSelectElement>)=>{
    setAcregRef(event.target.value);
  }
  const handleOwnerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOwner(event.target.value);
    setOwnerRef(event.target.value);
  };
  const filteredACRegs = () => {
    return acregs.filter((acreg) => {
      const index = selectedActype.indexOf('$');
      let actype1 = acreg.actype;
      if (index > 0) {
        actype1 = actype1.slice(0, index) + "$" + actype1.slice(index + 1);
      }
      return actype1 === selectedActype || (selectedActype === 'A321' && actype1 === 'A32I');
    });
  };
  const [actypeRef,setActypeRef] = useState<String>(state?.actype||"");
  const [acregRef,setAcregRef] = useState<String>(state?.acreg||"");
  const [icaoRef,setIcaoRef] = useState<String>(state?.icao||"HKG");
  const [ownerRef,setOwnerRef] = useState<String>(state?.owner||"");
  const [fltnumRef,setFltnumRef] = useState<String>(state?.fltnum||"000");
  const navigate = useNavigate();
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formState = {
      actype: actypeRef,
      acreg:acregRef,
      icao: icaoRef,
      owner: ownerRef?ownerRef:"CX",
      fltnum:fltnumRef
    };
    console.log("LOG - Submit FormState: ",formState)
    navigate(`${(window as any)._navbase}/acars1`, { state: {"state":formState,"p1State":null}  });
  };
  return (
    <div>
      <Helmet>
        <title>{data ? `RTOW Page 0` : 'Loading...'}</title>
        <meta name='description' content='RTOW Application Page' />
      </Helmet>
      {data ? (
        <>
          <h1>Web RTOW</h1>
          <form name='ACList' method='post' onSubmit={handleSubmit}>
            <table>
              <tbody>
                <tr>
                  <td>Aircraft Type</td>
                  <td>
                    <select name='actype' onChange={handleActypeChange} defaultValue={state?.actype||""}>
                      {data.actypes.map((actype) => (
                        <option key={actype.value} value={actype.value}>
                          {actype.desc}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td>Aircraft Registration</td>
                  <td>
                    <select name='acreg' onChange={handleAcRegChange} defaultValue={state?.acreg||""}>
                      {filteredACRegs().map((acreg) => (
                        <option key={acreg.acreg} value={acreg.acreg}>
                          {acreg.acreg}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td>Airport e.g. HKG or VHHH</td>
                  <td>
                    <input type='text' name='icao'  defaultValue={state?.icao||"HKG"} onChange={e => setIcaoRef(e.target.value)}/>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td>Flight owner</td>
                  <td>
                    <select
                      name='owner'
                        onChange={handleOwnerChange}
                      value={selectedOwner}
                    defaultValue={state?.owner||""} 
                    >
                      {data.owners.map((owner) => (
                        <option key={owner} value={owner}>
                          {owner}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td>Flt number (option) e.g. 451</td>
                  <td>
                    <input type='text' name='fltnum' defaultValue={state?.fltnum||"000"}  onChange={e => setFltnumRef(e.target.value)} />
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <input type='submit' value='Next >>' />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </>
      ) : pageError ? (
        pageError
      ) : (
        'Loading...'
      )}
    </div>
  );
};
export default Acars0;