import React, { useEffect, useState } from 'react';

export interface AcarsFormProps {
  actype: string;
  iata: string;
  icao: string;
  acreg: string;
  fltnum: string;
  isb747: boolean;
  isb777: boolean;
  iselev: boolean;
  isairbus: boolean;
  header: string;
  acmldw: string;
  rwylist: [string, string][];
  mel_options: string[];
  nnc_options: string[];
  timenow: string;
  timestamp: string;
  onSubmit: (formData: AcarsFormData) => void;
  prev:AcarsFormData
}

export interface AcarsFormData {
  actype: string;
  apcode: string;
  icao: string;
  acreg: string;
  fltnum: string;
  isb747: string;
  isb777: string;
  iselev: string;
  isairbus: string;
  ldw: string;
  rwy: string;
  lda: string;
  surf: string;
  oat: string;
  flap: string;
  qnh: string;
  wind: string;
  aice: string;
  spdinc: string;
  fail: string;
  fail2: string;
  fail3: string;
  autoland: string;
  mel1: string;
  rev: string;
  mel2: string;
  athr: string;
  cdl: string;
  slope: string;
}

const AcarsForm: React.FC<AcarsFormProps> = ({
  actype,
  iata,
  icao,
  acreg,
  fltnum,
  isb747,
  isb777,
  iselev,
  isairbus,
  header,
  acmldw,
  rwylist,
  mel_options,
  nnc_options,
  timenow,
  timestamp,
  onSubmit,
  prev
}) => {
  const [formData, setFormData] = useState<AcarsFormData>();

  useEffect(()=>{
    setFormData({ 
      ...formData, 
      lda: prev?.lda||"", 
      ldw: prev?.ldw||"", 
      rwy: prev?.rwy||"", 
      oat: prev?.oat||"", 
      qnh: prev?.qnh||"", 
      slope: prev?.slope||"", 
      wind: prev?.wind||"", 
      spdinc: prev?.spdinc||"", 
      fail: prev?.fail||"", 
      mel1: prev?.mel1||"", 
      mel2: prev?.mel2||"", 
      cdl: prev?.cdl||"", 
    } as AcarsFormData)
  },[])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.currentTarget as HTMLFormElement;
    const formValues = new FormData(form);
    const data = Object.fromEntries(formValues.entries());

    const formData1 = data as unknown as AcarsFormData;
    setFormData(formData1);

    onSubmit(formData1);
  };

  return (
    <div>
      <style>
        {`body {
                    font-size: 12pt;
                    font-family: Verdana, Arial, Helvetica
                }
                input {
                    font-size: 12pt;
                    font-family: Verdana, Arial, Helvetica
                }
                select {
                    font-size: 12pt;
                    font-family: Verdana, Arial, Helvetica
                }
                td {
                    background-color: #ffffff;
                    border-style: solid;
                    border-color: #000000;
                    border-width: 2px;
                }`}
      </style>
      <div id='content'>
        <form name='acinfo' onSubmit={handleSubmit}>
          <input type='hidden' name='actype' value={actype} />
          <input type='hidden' name='apcode' value={iata} />
          <input type='hidden' name='icao' value={icao} />
          <input type='hidden' name='acreg' value={acreg} />
          <input type='hidden' name='fltnum' value={fltnum} />
          <input type='hidden' name='isb747' value={isb747 ? '1' : '0'} />
          <input type='hidden' name='isb777' value={isb777 ? '1' : '0'} />
          <input type='hidden' name='iselev' value={iselev ? '1' : '0'} />
          <input type='hidden' name='isairbus' value={isairbus ? '1' : '0'} />
          <div style={{ width: '650px' }}>
            <center style={{ fontSize: '20pt' }}>{header}</center>
            <center style={{ fontSize: '16pt' }}>B- {acreg}</center>

            {iselev && <input type='hidden' name='slope' value={formData?.slope || '-----'} />}

            <table width='100%' bgcolor={'#000000'}>
              <tbody>
                <tr>
                  <td colSpan={2} align='center'>
                    LANDING DATA 1/2
                  </td>
                </tr>
                <tr>
                  <td width='50%'>
                    {iselev ? (
                      <>
                        AP Elev ~ FT
                        <br />
                        <div id='APCODE'>{icao}</div>
                      </>
                    ) : (
                      <>
                        AP CODE:
                        <br />
                        <div id='APCODE'>{iata}</div>
                      </>
                    )}
                  </td>
                  <td align='right'>
                    ALW - T<br />
                    <input
                      type='text'
                      name='ldw'
                      value={formData?.ldw || String(parseFloat(acmldw).toFixed(1))}
                      size={5}
                      title='Enter actual LDW in ton here e.g. 213.2'
                      style={{ textAlign: 'right' }}
                      onChange={(e) =>
                        setFormData({ ...formData, ldw: e.target.value } as AcarsFormData)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td width='50%'>
                    {iselev ? (
                      <>
                        {isairbus ? (
                          <>
                            LDA - M<br />
                            <input
                              type='text'
                              name='lda'
                              value={formData?.lda || '2000'}
                              size={5}
                              title='Enter length of LDA in meter e.g. 2000'
                              style={{ textAlign: 'left' }}
                              onChange={(e) =>
                                setFormData({ ...formData, lda: e.target.value } as AcarsFormData)
                              }
                            />
                          </>
                        ) : (
                          <>
                            LDA - FT
                            <br />
                            <input
                              type='text'
                              name='lda'
                              value={formData?.lda || '7000'}
                              size={5}
                              title='Enter length of LDA in feet e.g. 9000'
                              style={{ textAlign: 'left' }}
                              onChange={(e) =>
                                setFormData({ ...formData, lda: e.target.value } as AcarsFormData)
                              }
                            />
                          </>
                        )}
                        <input type='hidden' name='rwy' value='0' />
                      </>
                    ) : (
                      <table width='100%'>
                        <tbody>
                          <tr>
                            <td width='50%'>
                              RWY CODE:
                              <br />
                              <select name='rwy' size={1} defaultValue={prev?.rwy||""}>
                                {rwylist.map(([value, label]) => (
                                  <option key={value} value={value}>
                                    {label}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td align='right'>
                              {isairbus ? (
                                <>
                                  LDA - M<br />
                                  <input
                                    type='text'
                                    name='lda'
                                    value={formData?.lda || ''}
                                    size={5}
                                    title='Enter length of LDA in meter e.g. 2000, leave blank to select by runway designator'
                                    style={{ textAlign: 'left' }}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        lda: e.target.value,
                                      } as AcarsFormData)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  LDA - FT
                                  <br />
                                  <input
                                    type='text'
                                    name='lda'
                                    value={formData?.lda || ''}
                                    size={5}
                                    title='Enter length of LDA in feet e.g. 9000, leave blank to select by runway designator'
                                    style={{ textAlign: 'left' }}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        lda: e.target.value,
                                      } as AcarsFormData)
                                    }
                                  />
                                </>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </td>
                  <td align='right'>
                    {isairbus ? (
                      <>
                        RCAM
                        <br />
                        <select name='surf' size={1} defaultValue={prev?.surf||""}>
                          <option value='DRY'>DRY</option>
                          <option value='GOOD'>GOOD</option>
                          <option value='GM'>GOOD to MEDIUM</option>
                          <option value='MED'>MEDIUM</option>
                          <option value='MP'>MEDIUM to POOR</option>
                          <option value='POOR'>POOR</option>
                        </select>
                      </>
                    ) : (
                      <>
                        RWY SURF
                        <br />
                        <select name='surf' size={1} defaultValue={prev?.surf||""}>
                          <option value='DRY'>DRY</option>
                          <option value='WET'>WET</option>
                          <option value='GOOD'>GOOD</option>
                          <option value='GM'>GOOD to MEDIUM</option>
                          <option value='MED'>MEDIUM</option>
                          <option value='MP'>MEDIUM to POOR</option>
                          <option value='POOR'>POOR</option>
                        </select>
                      </>
                    )}
                  </td>
                </tr>
                {isairbus && (
                  <AirbusLandingData
                    iselev={iselev}
                    mel_options={mel_options}
                    nnc_options={nnc_options}
                    actype={actype}
                    formData={formData}
                    setFormData={setFormData}
                    prev={prev}
                  />
                )}
                {isb747 && (
                  <B747LandingData
                    iselev={iselev}
                    mel_options={mel_options}
                    nnc_options={nnc_options}
                    formData={formData}
                    setFormData={setFormData}
                    prev={prev}
                  />
                )}
                {isb777 && (
                  <B777LandingData
                    iselev={iselev}
                    mel_options={mel_options}
                    nnc_options={nnc_options}
                    formData={formData}
                    setFormData={setFormData}
                    prev={prev}
                  />
                )}
              </tbody>
            </table>
          </div>

          <input type='submit' value='Submit' />
        </form>

        <br />
        <p className='content'>
          <i>
            Generated on {timenow} (HKG LT)
            <br />
            by Ops Eng, FOP, Cathay Pacific Airways
          </i>
        </p>
      </div>
      {/* <script src={`/static/js/acarsl1.js?v=${timestamp}`} /> */}
    </div>
  );
};

const AirbusLandingData: React.FC<{
  iselev: boolean;
  mel_options: string[];
  nnc_options: string[];
  actype: string;
  formData: AcarsFormData | undefined;
  setFormData: (formData: AcarsFormData) => void;
  prev:AcarsFormData
}> = ({ iselev, mel_options, nnc_options, actype, formData, setFormData,prev }) => (
  <>
    <tr>
      <td width='50%'>
        OAT - C<br />
        <input
          type='text'
          name='oat'
          value={formData?.oat || '20'}
          size={3}
          title='OAT in degree celsius e.g. -10 or 20'
          onChange={(e) => setFormData({ ...formData, oat: e.target.value } as AcarsFormData)}
        />
      </td>
      <td align='right'>
        CONF
        <br />
        <select name='flap' size={1} defaultValue={prev?.flap||""}>
          <option value='OPT'>OPT</option>
          <option value='FULL'>FULL</option>
          <option value='3'>3</option>
          <option value='2'>2</option>
          <option value='1'>1</option>
        </select>
      </td>
    </tr>
    <tr>
      <td>
        QNH - hPa
        <br />
        <input
          type='text'
          name='qnh'
          value={formData?.qnh || '1013'}
          size={4}
          title='QNH in hPa e.g. 990 or 1020'
          onChange={(e) => setFormData({ ...formData, qnh: e.target.value } as AcarsFormData)}
        />
      </td>
      <td align='right'>
        {iselev && (
          <>
            SLOPE U/D
            <br />
            <input
              type='text'
              name='slope'
              value={formData?.slope || '-----'}
              size={5}
              style={{ textAlign: 'right' }}
              title='Runway slope in % (e.g. 1.50D = 1.5% download hill, 0.02U = 0.02% uphill)'
              onChange={(e) => setFormData({ ...formData, slope: e.target.value } as AcarsFormData)}
            />
          </>
        )}
      </td>
    </tr>
    <tr>
      <td width='50%'>
        WIND H/T
        <br />
        <input
          type='text'
          name='wind'
          value={formData?.wind || '0H'}
          size={3}
          title='wind head or tail e.g. 5T or 10H'
          onChange={(e) => setFormData({ ...formData, wind: e.target.value } as AcarsFormData)}
        />
      </td>
      <td align='right'>
        ICE ACRTN
        <br />
        <select name='aice' size={1} title='ICE Accretion' defaultValue={prev?.aice||""}>
          <option value='N' title='Without ice accretion'>
            N
          </option>
          <option value='Y' title='With ice accretion'>
            Y
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <td colSpan={2} align='center'>
        LANDING DATA 2/2
      </td>
    </tr>
    <tr>
      <td width='50%'>
        SPD INCR
        <br />
        <input
          type='text'
          name='spdinc'
          value={formData?.spdinc || '00'}
          size={2}
          title='enter extra pilot increment here'
          onChange={(e) => setFormData({ ...formData, spdinc: e.target.value } as AcarsFormData)}
        />
      </td>
      <td align='right'>
        FAIL CODE 1<br />
        <span style={{ fontSize: '8pt' }}>(In-flight failure item)</span>
        <br />
        <input
          type='text'
          name='fail'
          value={formData?.fail || 'N'}
          size={15}
          style={{ textAlign: 'right' }}
          title='Enter in-flight fail code here'
          onChange={(e) => setFormData({ ...formData, fail: e.target.value } as AcarsFormData)}
        />
      </td>
    </tr>
    <tr>
      <td width='50%'>
        AUTO LAND
        <br />
        <select name='autoland' size={1} title='N=NO AUTO LAND; Y=using AUTO LAND' defaultValue={prev?.autoland||""}>
          <option value='N' title='MANUAL LAND'>
            N
          </option>
          <option value='Y' title='AUTO LAND'>
            Y
          </option>
        </select>
      </td>
      <td align='right'>
        FAIL CODE 2<br />
        <span style={{ fontSize: '8pt' }}>(MEL item)</span>
        <br />
        <input
          type='text'
          name='mel1'
          value={formData?.mel1 || 'N'}
          size={15}
          style={{ textAlign: 'right' }}
          title='Enter despatch MEL item here'
          onChange={(e) => setFormData({ ...formData, mel1: e.target.value } as AcarsFormData)}
        />
      </td>
    </tr>
    <tr>
      <td width='50%'>
        REV
        <br />
        <select name='rev' size={1} title='No of Reverse Credit available' defaultValue={prev?.rev||""}>
          <option value='0' title='NO REV CREDIT'>
            0
          </option>
          <option value='1' title='WITH 1 REV CREDIT'>
            1
          </option>
          <option value='2' title='WITH 2 REV CREDIT'>
            2
          </option>
          {actype === 'A340' && (
            <>
              <option value='3' title='WITH 3 REV CREDIT'>
                3
              </option>
              <option value='4' title='WITH 4 REV CREDIT'>
                4
              </option>
            </>
          )}
        </select>
      </td>
      <td align='right'>
        FAIL CODE 3<br />
        <span style={{ fontSize: '8pt' }}>(MEL item)</span>
        <br />
        <input
          type='text'
          name='mel2'
          value={formData?.mel2 || 'N'}
          size={15}
          style={{ textAlign: 'right' }}
          title='Enter despatch MEL item here'
          onChange={(e) => setFormData({ ...formData, mel2: e.target.value } as AcarsFormData)}
        />
      </td>
    </tr>
    <tr>
      <td>
        A/THR
        <br />
        <select name='athr' size={1} defaultValue={prev?.athr||""}>
          <option value='Y' title='AUTO THRUST'>
            Y
          </option>
          <option value='N' title='MANUAL THRUST'>
            N
          </option>
        </select>
      </td>
      <td align='right'>
        CDL
        <br />
        <input
          type='text'
          name='cdl'
          value={formData?.cdl || 'N'}
          size={15}
          style={{ textAlign: 'right' }}
          title='Put LST to get the list of available choices or a positive number for a simple weight reduction'
          onChange={(e) => setFormData({ ...formData, cdl: e.target.value } as AcarsFormData)}
        />
      </td>
    </tr>
  </>
);

const B747LandingData: React.FC<{
  iselev: boolean;
  mel_options: string[];
  nnc_options: string[];
  formData: AcarsFormData | undefined;
  setFormData: (formData: AcarsFormData) => void;
  prev:AcarsFormData
}> = ({ iselev, mel_options, nnc_options, formData, setFormData,prev }) => (
  <>
    <tr>
      <td width='50%'>
        OAT - C<br />
        <input
          type='text'
          name='oat'
          value={formData?.oat || '20'}
          size={3}
          title='OAT in degree celsius e.g. -10 or 20'
          onChange={(e) => setFormData({ ...formData, oat: e.target.value } as AcarsFormData)}
        />
      </td>
      <td align='right'>
        FLAP
        <br />
        <select name='flap' size={1} defaultValue={prev?.flap||""}>
          <option value='OPT'>OPT</option>
          <option value='F30'>F30</option>
          <option value='F25'>F25</option>
        </select>
      </td>
    </tr>
    <tr>
      <td>
        QNH - hPa
        <br />
        <input
          type='text'
          name='qnh'
          value={formData?.qnh || '1013'}
          size={4}
          title='QNH in hPa e.g. 990 or 1020'
          onChange={(e) => setFormData({ ...formData, qnh: e.target.value } as AcarsFormData)}
        />
      </td>
      <td align='right'>
        {iselev && (
          <>
            SLOPE U/D
            <br />
            <input
              type='text'
              name='slope'
              value={formData?.slope || '-----'}
              size={5}
              style={{ textAlign: 'right' }}
              title='Runway slope in % (e.g. 1.50D = 1.5% download hill, 0.02U = 0.02% uphill)'
              onChange={(e) => setFormData({ ...formData, slope: e.target.value } as AcarsFormData)}
            />
          </>
        )}
      </td>
    </tr>
    <tr>
      <td width='50%'>
        WIND H/T
        <br />
        <input
          type='text'
          name='wind'
          value={formData?.wind || '0H'}
          size={3}
          title='wind head or tail e.g. 5T or 10H'
          onChange={(e) => setFormData({ ...formData, wind: e.target.value } as AcarsFormData)}
        />
      </td>
      <td> </td>
    </tr>
    <tr>
      <td colSpan={2} align='center'>
        LANDING DATA 2/2
      </td>
    </tr>
    <tr>
      <td width='50%'>
        VRef+
        <br />
        <input
          type='text'
          name='spdinc'
          value={formData?.spdinc || '05'}
          size={2}
          title='enter extra pilot increment here'
          onChange={(e) => setFormData({ ...formData, spdinc: e.target.value } as AcarsFormData)}
        />
      </td>
      <td align='right'>
        MEL-1
        <br />
        <select style={{ fontSize: '9pt' }} name='mel1' size={1} defaultValue={prev?.mel1||""}>
          <option value='N'>Normal</option>
          {mel_options.map((value, index) => {
            const val = value.slice(0,15).trimEnd();
            const legend = value.slice(15,85).trimEnd();
            return  (<option key={index} value={val} title={legend}>
                {val} {legend.slice(0,30)}
              </option>
            );
          })}
        </select>
      </td>
    </tr>
    <tr>
      <td width='50%'>
        AUTO LAND
        <br />
        <select name='autoland' size={1} title='N=NO AUTO LAND; Y=using AUTO LAND' defaultValue={prev?.autoland||""}>
          <option value='N' title='MANUAL LAND'>
            N
          </option>
          <option value='Y' title='AUTO LAND'>
            Y
          </option>
        </select>
      </td>
      <td align='right'>
        MEL-2
        <br />
        <select style={{ fontSize: '9pt' }} name='mel2' size={1} defaultValue={prev?.mel2||""}>
          <option value='N'>Normal</option>
          {mel_options.map((value, index) => {
            const val = value.slice(0,15).trimEnd();
            const legend = value.slice(15,85).trimEnd();
            return  (<option key={index} value={val} title={legend}>
                {val} {legend.slice(0,30)}
              </option>
            );
          })}
        </select>
      </td>
    </tr>
    <tr>
      <td width='50%'>
        REV
        <br />
        <select name='rev' size={1} title='No of Reverse Credit available' defaultValue={prev?.rev||""}>
          <option value='0' title='NO REV CREDIT'>
            0
          </option>
          <option value='2' title='WITH 2 REV CREDIT'>
            2
          </option>
          <option value='4' title='WITH 4 REV CREDIT'>
            4
          </option>
        </select>
      </td>
      <td align='right'>
        CDL
        <br />
        <input
          type='text'
          name='cdl'
          value={formData?.cdl || 'N'}
          size={15}
          style={{ textAlign: 'right' }}
          title='Put LST to get the list of available choices or a positive number for a simple weight reduction'
          onChange={(e) => setFormData({ ...formData, cdl: e.target.value } as AcarsFormData)}
        />
      </td>
    </tr>
    <tr>
      <td width='50%'> </td>
      <td align='right'>
        NON-NORM
        <br />
        <select style={{ fontSize: '9pt' }} name='fail' size={1} defaultValue={prev?.fail||""}>
          <option value='N'>N</option>
          {nnc_options.map((value, index) => {
            const val = value.slice(0,5).trimEnd();
            const legend = value.slice(11,30).trimEnd();
            return  (<option key={index} value={val}>
                {val} {legend.slice(0,30)}
              </option>
            );
          })}
        </select>
      </td>
    </tr>
  </>
);

const B777LandingData: React.FC<{
  iselev: boolean;
  mel_options: string[];
  nnc_options: string[];
  formData: AcarsFormData | undefined;
  setFormData: (formData: AcarsFormData) => void;
  prev:AcarsFormData
}> = ({ iselev, mel_options, nnc_options, formData, setFormData,prev }) => (
  <>
    <tr>
      <td width='50%'>
        OAT - C<br />
        <input
          type='text'
          name='oat'
          value={formData?.oat || '20'}
          size={3}
          title='OAT in degree celsius e.g. -10 or 20'
          onChange={(e) => setFormData({ ...formData, oat: e.target.value } as AcarsFormData)}
        />
      </td>
      <td align='right'>
        SPD INCR
        <br />
        <input
          type='text'
          name='spdinc'
          value={formData?.spdinc || '00'}
          size={2}
          title='enter extra pilot increment here'
          onChange={(e) => setFormData({ ...formData, spdinc: e.target.value } as AcarsFormData)}
        />
      </td>
    </tr>
    <tr>
      <td>
        QNH - hPa
        <br />
        <input
          type='text'
          name='qnh'
          value={formData?.qnh || '1013'}
          size={4}
          title='QNH in hPa e.g. 990 or 1020'
          onChange={(e) => setFormData({ ...formData, qnh: e.target.value } as AcarsFormData)}
        />
      </td>
      <td align='right'>
        {iselev && (
          <>
            SLOPE U/D
            <br />
            <input
              type='text'
              name='slope'
              value={formData?.slope || '-----'}
              size={5}
              style={{ textAlign: 'right' }}
              title='Runway slope in % (e.g. 1.50D = 1.5% download hill, 0.02U = 0.02% uphill)'
              onChange={(e) => setFormData({ ...formData, slope: e.target.value } as AcarsFormData)}
            />
          </>
        )}
      </td>
    </tr>
    <tr>
      <td width='50%'>
        WIND H/T
        <br />
        <input
          type='text'
          name='wind'
          value={formData?.wind || '0H'}
          size={3}
          title='wind head or tail e.g. 5T or 10H'
          onChange={(e) => setFormData({ ...formData, wind: e.target.value } as AcarsFormData)}
        />
      </td>
      <td> </td>
    </tr>
    <tr>
      <td colSpan={2} align='center'>
        LANDING DATA 2/2
      </td>
    </tr>
    <tr>
      <td width='50%'>
        AUTO LAND
        <br />
        <select name='autoland' size={1} title='N=NO AUTO LAND; Y=using AUTO LAND' defaultValue={prev?.autoland||""}>
          <option value='N' title='MANUAL LAND'>
            N
          </option>
          <option value='Y' title='AUTO LAND'>
            Y
          </option>
        </select>
      </td>
      <td align='right'>
        MEL-1
        <br />
        <select style={{ fontSize: '9pt' }} name='mel1' size={1} defaultValue={prev?.mel1||""}>
          <option value='N'>Normal</option>
          {mel_options.map((value, index) => {
            const val = value.slice(0,15).trimEnd();
            const legend = value.slice(15,85).trimEnd();
            return  (<option key={index} value={val} title={legend}>
                {val} {legend.slice(0,30)}
              </option>
            );
          })}
        </select>
      </td>
    </tr>
    <tr>
      <td width='50%'>
        NON-NORM
        <br />
        <select style={{ fontSize: '9pt' }} name='fail' size={1} defaultValue={prev?.fail||""}>
          <option value='N'>N</option>
          {nnc_options.map((value, index) => {
            const val = value.slice(0,5).trimEnd();
            const legend = value.slice(11,30).trimEnd();
            return  (<option key={index} value={val}>
                {val} {legend}
              </option>
            );
          })}
        </select>
      </td>
      <td align='right'>
        MEL-2
        <br />
        <select style={{ fontSize: '9pt' }} name='mel2' size={1} defaultValue={prev?.mel2||""}>
          <option value='N'>Normal</option>
          {mel_options.map((value, index) => {
            const val = value.slice(0,15).trimEnd();
            const legend = value.slice(15,85).trimEnd();
            return  (<option key={index} value={val} title={legend}>
                {val} {legend.slice(0,30)}
              </option>
            );
          })}
        </select>
      </td>
    </tr>
    <tr>
      <td width='50%'>
        FLAP
        <br />
        <select name='flap' size={1} defaultValue={prev?.flap||""}>
          <option value='F25'>F25</option>
          <option value='OPT'>OPT</option>
          <option value='F30'>F30</option>
          <option value='F20'>F20</option>
        </select>
      </td>
      <td align='right'>
        CDL
        <br />
        <input
          type='text'
          name='cdl'
          value={formData?.cdl || 'N'}
          size={15}
          style={{ textAlign: 'right' }}
          title='Put LST to get the list of available choices or a positive number for a simple weight reduction'
          onChange={(e) => setFormData({ ...formData, cdl: e.target.value } as AcarsFormData)}
        />
      </td>
    </tr>
    <tr>
      <td width='50%'> </td>
      <td align='right'>
        REV
        <br />
        <select name='rev' size={1} title='No of Reverse Credit available' defaultValue={prev?.rev||""}>
          <option value='0' title='NO REV CREDIT'>
            0
          </option>
          <option value='1' title='WITH 1 REV CREDIT'>
            1
          </option>
          <option value='2' title='WITH 2 REV CREDIT'>
            2
          </option>
        </select>
      </td>
    </tr>
  </>
);

export default AcarsForm;
