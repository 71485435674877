import { createContext, ReactNode, useContext, useState } from 'react';

type UserContextProps = {
  setUserInfo: (token?: string) => void;
  hasSomeGroup: (groups: string[]) => boolean;
  hasAllGroup: (groups: string[]) => boolean;
  hasSomeRole: (roles: string[]) => boolean;
  hasAllRole: (roles: string[]) => boolean;
  groups: string[];
  roles: string[];
  name: string;
  preferredUsername: string;
};
const initContext: UserContextProps = {
  setUserInfo: (_) => {},
  hasSomeGroup: (_) => false,
  hasAllGroup: (_) => false,
  hasSomeRole: (_) => false,
  hasAllRole: (_) => false,
  groups: [],
  roles: [],
  name: '',
  preferredUsername: '',
};
const UserContext = createContext<UserContextProps>(initContext);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [context, setContext] = useState<UserContextProps>(initContext);
  const setUserInfo = (token?: string) => {
    if (!token) return;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    const { groups, roles, name, preferred_username } = JSON.parse(jsonPayload);
    setContext({ ...context, groups, roles, name, preferredUsername: preferred_username });
  };
  const hasSomeGroup = (groups: string[]) => {
    return groups.length === 0 || context.groups.some((group) => context.groups.includes(group));
  };
  const hasAllGroup = (groups: string[]) => {
    return groups.length === 0 || groups.every((group) => context.groups.includes(group));
  };
  const hasSomeRole = (roles: string[]) => {
    return roles.length === 0 || roles.some((role) => context.roles.includes(role));
  };
  const hasAllRole = (roles: string[]) => {
    return roles.length === 0 || roles.every((role) => context.roles.includes(role));
  };

  return (
    <UserContext.Provider
      value={{ ...context, setUserInfo, hasSomeGroup, hasAllGroup, hasSomeRole, hasAllRole }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
