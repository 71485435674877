import { Navigate } from 'react-router';
import { useUserContext } from '../providers/UserProvider';

type WithPermissionProps = {
  someGroups?: string[];
  allGroups?: string[];
  someRoles?: string[];
  allRoles?: string[];
  redirectTo?: string;
  children: React.ReactNode;
};

const WithPermission = ({
  children,
  someGroups,
  allGroups,
  someRoles,
  allRoles,
  redirectTo,
}: WithPermissionProps) => {
  const { groups, hasSomeGroup, hasAllGroup, hasSomeRole, hasAllRole } = useUserContext();
  if (!groups.length) {
    return null;
  }
  if (someGroups && !hasSomeGroup(someGroups)) {
    return redirectTo ? <Navigate to={redirectTo} replace /> : null;
  }
  if (allGroups && !hasAllGroup(allGroups)) {
    return redirectTo ? <Navigate to={redirectTo} replace /> : null;
  }
  if (someRoles && !hasSomeRole(someRoles)) {
    return redirectTo ? <Navigate to={redirectTo} replace /> : null;
  }
  if (allRoles && !hasAllRole(allRoles)) {
    return redirectTo ? <Navigate to={redirectTo} replace /> : null;
  }
  return <>{children}</>;
};

export default WithPermission;
