import axios from "axios";
import webConfig from '../config';
const { SERVER_BASE_URL, WHITE_LIST_API } = webConfig;

export interface RequestParams {
  actype: string;
  acreg: string;
  icao: string;
  owner: string;
  fltnum: string;
}

export const postAcarsl1DataByAxios = async (
  params: RequestParams
): Promise<any> => {
  console.log(params);

  const baseUrl = `${SERVER_BASE_URL}/acarsl1`;
  const queryParams = params && new URLSearchParams(Object.entries(params));
  const url = baseUrl + `?${queryParams}`;
  const headers = {
    'Cache-Control': 'no-store, max-age=0',
    Accept: '*/*',
    "Content-Type": "application/json",
  };
  const response = await axios.post(url, undefined, { headers });
  return response?.data;
};