import React, { useEffect, useRef, useState } from 'react';
import { DataResponse, getAcarsl0DataByAxios,RequestParams } from '../../services/acarsl0';
import { useLocation,useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const Acarsl0: React.FC = () => {
  const location = useLocation();
  const state = location.state as RequestParams;
  const [selectedAcType, setSelectedAcType] = useState(state?.actype||"");
  const [selectedAcReg, setSelectedAcReg] = useState(state?.acreg||"");
  const [icao, setIcao] = useState(state?.icao||'HKG');
  const [selectedOwner, setSelectedOwner] = useState(state?.owner||'CX');
  const [flightNumber, setFlightNumber] = useState(state?.fltnum||'0000');
  const [pageError, setPageError] = useState<string>('');
  const [firstLoading, setFirstLoading] = useState<Boolean>(true);
  const acregRef = useRef<HTMLSelectElement>(null);
  const navigate = useNavigate();
  const [data, setData] = useState<DataResponse | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      const result = await getAcarsl0DataByAxios(selectedOwner).catch((error) => {
        console.error('Error fetching data:', error);
        setPageError(error.message);
      });
      setData(result || null);
      if(state!=null&&firstLoading){
        console.log("First Loading!!!")
        console.log("LOG - P1 state:",state)
        setFirstLoading(false)
      }
      else if (result && result.actypes.length > 0) {
        setSelectedAcType(result.actypes[0].value);
      }
    };
    selectedOwner && fetchData();
  }, [selectedOwner]);
  const handleAcTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAcType(e.target.value);
    setSelectedAcReg(acregRef.current?.value || '');
    // Call GetACRegs() function here to update the aircraft registration dropdown
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formState = {
      actype: selectedAcType,
      acreg: acregRef.current?.value,
      icao: icao,
      owner: selectedOwner,
      fltnum: flightNumber,
    };
    console.log("LOG - formState:",formState);
    // Route to Acars1 page
    // navigate(`${(window as any)._navbase}/acarsl1`, { state: formState });
    navigate(`${(window as any)._navbase}/acarsl1`, { state: {"state":formState,"p1State":null} });
  };
  const filteredACRegs = () => {
    return data
      ? data.acregs.filter((acreg) => {
          const index = selectedAcType.indexOf('X');
          let actype1 = acreg.actype;
          if (index > 0) {
            actype1 = actype1.slice(0, index) + "X" + actype1.slice(index + 1);
          }
          return actype1 === selectedAcType || (selectedAcType === 'A321' && actype1 === 'A32I');
        })
      : [];
  };
  return (
    <>
      <Helmet>
        <title>{data ? `LAND Page 0` : 'Loading...'}</title>
        <meta name='description' content='LAND Application Page' />
      </Helmet>
      {data ? (
        <form name='ACList' onSubmit={handleSubmit}>
          <h1>Web LANDING</h1>
          <table>
            <tr>
              <td>Aircraft Type</td>
              <td>
                <select name='actype' value={selectedAcType} onChange={handleAcTypeChange}>
                  {data &&
                    data.actypes.map((actype) => (
                      <option key={actype.value} value={actype.value}>
                        {actype.desc}
                      </option>
                    ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>Aircraft Registration</td>
              <td>
                <select
                  name='acreg'
                  ref={acregRef}
                  value={selectedAcReg}
                  onChange={(e) => setSelectedAcReg(e.target.value)}
                >
                  {filteredACRegs().map((acreg) => (
                    <option key={acreg.acreg} value={acreg.acreg}>
                      {acreg.acreg}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                Airport code or Elev in ft
                <br />
                e.g. HKG or 28
              </td>
              <td>
                <input
                  type='text'
                  name='icao'
                  value={icao}
                  onChange={(e) => setIcao(e.target.value)}
                  size={5}
                />
              </td>
            </tr>
            <tr>
              <td>Flight owner</td>
              <td>
                <select
                  name='owner'
                  value={selectedOwner}
                  onChange={(e) => setSelectedOwner(e.target.value)}
                >
                  {data &&
                    data.owners.map((owner) => (
                      <option key={owner} value={owner}>
                        {owner}
                      </option>
                    ))}
                </select>
              </td>
            </tr>
            <tr>
              <td align='left'>Flt number (optional) e.g. 451</td>
              <td align='left'>
                <input
                  type='text'
                  name='fltnum'
                  value={flightNumber}
                  onChange={(e) => setFlightNumber(e.target.value)}
                  size={4}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <input type='submit' value='Next >>' />
              </td>
            </tr>
          </table>
        </form>
      ) : pageError ? (
        pageError
      ) : (
        'Loading...'
      )}
    </>
  );
};
export default Acarsl0;